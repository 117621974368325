import  { useEffect, useState } from 'react';
import api from '../../../services/api';
import Card from '../../../components/Card';


export default function () {
  const [data, setData] = useState(0)
  useEffect(() => {
    api.get("hermes/dashboard/backup/all").then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  },[])
    return (
    <Card title={data} subtitle="Backups realizados"/>
  );
}