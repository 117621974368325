import React, { useEffect, useState } from 'react';
import Datatable from '../../../components/Datatable';
import FormDialog from '../../../components/Datatable/FormDialog';
import { Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import { useNotify } from '../../../components/Datatable/NotifyProvider';
import { useFormError } from '../../../components/Datatable/FormErrorProvider';
import { ApiTwoTone, RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../../components/AuthProvider';
import moment from 'moment';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import OltSelect from '../../../components/OltSelect';
import CtoSelect from '../../../components/CtoSelect';
import OnuSearchDetail from './OnuSearchDetail';
import { ONUHistorySignalButton } from '../../../components/Graphs/SignaHistoryOnuGraph';
import { ONUHistoryDownButton } from '../../../components/Graphs/DownHistoryOnuGraph';
import CustomTextField from '../../../components/CustomTextField';
import CircleIcon from '@mui/icons-material/Circle';
import { Renderer } from 'leaflet';
import 'moment/locale/pt-br';
import { green, grey, red } from '@mui/material/colors';

export default function ONU() {
    const notify = useNotify()
    const auth = useAuth()
    const error = useFormError()

    const [formState, setFormState] = useState({
        open: false,
        id: null,
        port: null,
        board: null,
        olt: null,
    })
    const [formSignalState, setFormSignalState] = useState({
        open: false,
        sn: null,
        olt: null
    })
    const [formAccess, setFormAccess] = useState({
        open: false,
        id: null,
    })
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        error.setErrors([])
        document.title = "IPV7 Onu's - Clientes"
    }, [])
    return (
        <>
            <OnuSearchDetail
                open={formSignalState.open}
                sn={formSignalState.sn}
                olt={formSignalState.olt}
                handlerClose={() => setFormSignalState({ ...formSignalState, open: false })}
            />
            <FormItem
                open={formState.open}
                id_from_olt={formState.id_from_olt}
                olt={formState.olt}
                board={formState.board}
                port={formState.port}
                handlerClose={() => setFormState({ open: false, id: null })}
               handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Clientes'
                endpoint='/provisioning/onu'
                options={{
                    customToolbar: () =>
                        <>
                            
                            {/* <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm('onu.create')}
                                    onClick={() => setFormState({ open: true, id: null })}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip> */}
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id_from_olt',
                        label: 'ID',
                        options: {
                            display: false
                        }

                    },
                    {
                        name: 'olt',
                        label: 'olt',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'olt_name',
                        label: 'OLT',

                    },
                    {
                        name: 'empresa_name',
                        label: 'Empresa',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: "description",
                        label: "Descrição",
                    },
                    {
                        name: 'board',
                        label: 'Placa',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'port',
                        label: 'Porta',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: "sn",
                        label: "SN",
                    },
                    {
                        name: 'cto_name',
                        label: 'CTO',
                    },
                    {
                        name: 'signal',
                        label: 'Status',
                        options: {
                            customBodyRender: value => {
                                if (value == 0) {
                                    return (
                                    <Tooltip title="Sem Sinal">
                                        <CircleIcon style={{ color: red[500] }} />
                                    </Tooltip>)
                                } else if (value < 0) {
                                    return (
                                    <Tooltip title="Online">
                                    <CircleIcon style={{ color: green[500] }} />
                                    </Tooltip>)
                                } else {
                                    return (
                                    <Tooltip title="Sem Coleta">
                                    <CircleIcon style={{ color: grey[500] }} />
                                    </Tooltip>)
                                }
                            }
                        }
                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton disabled={!auth.hasPerm('onu.update')}
                                                onClick={() => setFormState({ open: true,
                                                    id_from_olt: tableMeta.rowData[0], 
                                                     olt: tableMeta.rowData[1], 
                                                     board: tableMeta.rowData[5] ,
                                                     port: tableMeta.rowData[6] ,
                                                    })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Sinal Atual">
                                            <IconButton disabled={!auth.hasPerm('onu.get_all')}
                                                onClick={() => setFormSignalState({ open: true, 
                                                     olt: tableMeta.rowData[1], 
                                                     sn: tableMeta.rowData[7] ,
                                                    })}
                                            >
                                                <NetworkCheckIcon />

                                            </IconButton>
                                        </Tooltip>
                                        <ONUHistorySignalButton sn={tableMeta.rowData[7]} title={tableMeta.rowData[4] + " - " + tableMeta.rowData[7]} />
                                        <ONUHistoryDownButton sn={tableMeta.rowData[7]} title={tableMeta.rowData[4] + " - " + tableMeta.rowData[7]} />
                                        



                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id_from_olt, port, board, olt, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()

    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {
        description: '',
        board: '',
        port: '',
        sn: '',
        cto: null,
        olt: null,
    }
    const [state, setState] = useState(modelState)

    useEffect(() => {
        setState(modelState)
        if (!open) return
        error.setErrors([])
        if (id_from_olt => 0 && olt && port && board) {
            setIsLoaded(false)
            api.get(`/provisioning/onu/${olt}/${board}/${port}/${id_from_olt}/`).then(res => {

                setState(res.data)
                setIsLoaded(true)

            }).catch(e => {
                console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id_from_olt, olt, port, board, open])



    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {

       
            api.put(`/provisioning/onu/${olt}/${board}/${port}/${id_from_olt}/`, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.error) {
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    }
                   if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Cliente atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
       
        handlerSubmit()

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">Editar ONU</Typography>
                       
                      
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CtoSelect

                        size="medium"
                        sx={{
                            mt: 1,
                            width: "sm",

                        }}
                        value={state?.cto}
                        handler={(e) => { setState({ ...state, cto: e }) }}
                        admin={false}
                    />
                    <CustomTextField
                        sx={{ mb: 2, mt: 2 }}
                        error={error.verify('description')}
                        helperText={error.msgError('description')}
                        label='Description'
                        disabled={true}
                        value={state?.description}
                        name={'description'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('sn')}
                        helperText={error.msgError('sn')}
                        label='SN'
                        disabled={true}
                        value={state?.sn}
                        name={'sn'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('board')}
                        helperText={error.msgError('board')}
                        label='Placa'
                        disabled={true}
                        value={state?.board}
                        name={'board'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('port')}
                        helperText={error.msgError('port')}
                        label='Porta'
                        disabled={true}
                        value={state?.port}
                        name={'port'}
                        onChange={handleChange}
                    />




                </Box>

            </FormDialog>
        </Box>
    )
}
