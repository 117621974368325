import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import api from "../../../services/api";
import { red, green, blue } from '@mui/material/colors';

export default function ClientsOLT() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        api.get("/provisioning/dashboard/count/clients_by_olt").then(res => {
            setData(res.data.data);
        }).finally(() => setLoading(false));
    }, []);

    // Função para obter um tom aleatório de uma cor específica
    const getRandomTone = (color) => {
        const tones = [300, 400, 500, 600, 700, 800, 900]; // Tons possíveis
        const randomIndex = Math.floor(Math.random() * tones.length);
        return color[tones[randomIndex]];
    };

    // Gerar uma lista de cores aleatórias para o número de entradas no data
    const barColors = data.map((_, index) => {
        if (index % 3 === 0) return getRandomTone(blue);
        if (index % 3 === 1) return getRandomTone(green);
        return getRandomTone(red);
    });

    return (
        <Paper elevation={2}  >
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center', mb: 2, fontFamily: 'Montserrat', fontWeight: 400 }}>
                Clientes por OLT
            </Typography>
            <ResponsiveContainer height={300} width={"100%"}>

                {loading ?
                    <LoadingPage />
                    :

                    <BarChart

                        data={data}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="olt_name" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="clientes">
                            {
                                data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={barColors[index]} />
                                ))
                            }
                        </Bar>
                    </BarChart>
                }
            </ResponsiveContainer>
        </Paper>
    );
}


function LoadingPage() {
    return (
        <Paper elevation={2}


            sx={{

                height: 300,
                width: "100%",
                p: 2,

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',

            }}
        >
            <CircularProgress
                disableShrink
            />
        </Paper>
    )
}