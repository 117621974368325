import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { duration } from 'moment';
export const NotifyContext = React.createContext();

export default function NotifyProvider({ children }) {
    const [notify, setNotify] = React.useState({
        open: false,
        message: 'Lorem ipsum...',
        severity: 'success',
        duration: 6000,
    });

    return (
        <NotifyContext.Provider value={{ notify, setNotify }}>
            <Snackbar
                open={notify.open}
                autoHideDuration={notify.duration}
                onClose={() => setNotify({ ...notify, open: false })}
                
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setNotify({ ...notify, open: false })} severity={notify.severity}>
                    {notify.message}
                </Alert>
            </Snackbar>
            
            {children}
        </NotifyContext.Provider>
    );
}

export function useNotify() {
    const context = React.useContext(NotifyContext);
    if (!context) {
        throw new Error('useNotify must be used within a NotifyProvider');
    }
    return context;
}

export function Snack({ open, message, severity = 'success', duration = 10000, handlerClose = () => {} }) {
    return (
        <Snackbar open={open} autoHideDuration={duration} onClose={handlerClose}>
            <Alert onClose={handlerClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
}

