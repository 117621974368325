import * as React from 'react';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Person } from '@mui/icons-material';
import { Box } from '@mui/material';
import OnuItem, { OnuItemBase } from '../../../../components/OnuItem';
import OnuSelect from '../../../../components/OnuSelect'
import api from '../../../../services/api';
import { useLoading } from '../../../../components/LoadingProvider'
import { useNotify } from '../../../../components/Datatable/NotifyProvider';
import { useFormError } from '../../../../components/Datatable/FormErrorProvider';
import theme from '../../../../themeContent';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function FormClient({ id, cto }) {
  const { setLoading } = useLoading()
  const notify = useNotify()
  const error = useFormError()
  const [open, setOpen] = React.useState(false);
  const [onus, setOnus] = React.useState([])
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fetchOnus = () => {
    setLoading(true)
    api.get("provisioning/cto/onu/" + id).then(res => {
      setOnus(res.data)
    }).finally(() => {
      setLoading(false)
    })
  }
  React.useEffect(() => {
    if (id > 0 && open) {
      fetchOnus()
    }
  }, [id, open])
  return (
    <ThemeProvider theme={theme}>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <Person />
      </IconButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          CTO {cto}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ minHeight: 600, minWidth: 400 }}>
          <OnuSelect size='small'
            sx={{
              width: 400,
              m: 1
            }}
            handler={(onu) => setOnus([onu, ...onus.filter(item => item.sn != onu.sn)])}
          />
          <Box>
            {onus?.map(onu => <OnuItemBase id={onu?.id_from_olt} olt={onu?.olt}
              handler={() => {
                if (confirm(`Tem certeza que deseja remover o Cliente ${onu.description} - ${onu.sn} da CTO? Está operação não remove o Cliente da OLT`)) {
                  setOnus(onus.filter(item => onu.sn != item.sn && onu.sn != item.olt))

                }
              }}
              description={onu?.description} state={"online"} sn={onu?.sn} />)}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose} >
            Cancelar
          </Button>
          <Button variant="contained" autoFocus onClick={() => {
            api.put("provisioning/cto/onus/update", {
              cto: id,
              onus
            }).then(res => {
              if (res.data.error) {
                notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                return
              }
              if (res.data.errors) {
                error.setErrors(res.data.errors)
                return
              }
              handleClose()
              notify.setNotify({ open: true, message: 'CTO atualizada com sucesso', severity: 'success' })
              error.setErrors([])
            })

          }}>
            Salvar alterações
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </ThemeProvider>
  );
}