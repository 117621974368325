import React, { useEffect } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { green, grey, red } from "@mui/material/colors";
import { Box, ListItemButton, Paper, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useFormError } from "../../../components/Datatable/FormErrorProvider";
import OltSelect from "../../../components/OltSelect";
import { Button } from '@mui/material';
import api from "../../../services/api";
import Datatable from '../../../components/Datatable';
import { Tooltip, IconButton } from "@mui/material";
import { Filter, Refresh, RemoveCircleOutline } from '@mui/icons-material';
import { useAuth } from "../../../components/AuthProvider";
import AddIcon from '@mui/icons-material/Add';
import { ThemeProvider, createTheme } from "@mui/material";
import { useLoading } from "../../../components/LoadingProvider";
import Divider from '@mui/material/Divider';
import OnuSearchDetail from "./OnuSearchDetail";
import './style.css'
import { CustomTabPanel, a11yProps } from "../../../components/Tabs";
import { useNotify } from "../../../components/Datatable/NotifyProvider";
import OnuItem from "../../../components/OnuItem";
import CustomTextField from "../../../components/CustomTextField";
import theme from "../../../themeContent";


export default function OnuSearch() {
  const [value, setValue] = React.useState(0);
  const error = useFormError()
  useEffect(()=>error.setErrors([]),[])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={3}>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', p:3 }}>
         
          <Typography variant={"h6"}>Busca de Clientes</Typography>
          
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Placa e Porta" {...a11yProps(0)} />
            <Tab label="SN" {...a11yProps(1)} />
          </Tabs>
          <CustomTabPanel value={value} index={0}>
            <OnusProv />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <OnuSearchBySn />

          </CustomTabPanel>
        </Box>
      </Paper>
    </ThemeProvider>
  )
}

function OnusProv() {
  const auth = useAuth()
  const [onuData, setOnuData] = React.useState([]);
  const [query, setQuery] = React.useState({
    board: "",
    port: "",
    olt: "",
  });
  const [querySignal, setQuerySignal] = React.useState({
    sn: "",
    olt: ""
  })
  const [filter, setFilter] = React.useState("");

  const loading = useLoading();
  const [formOpen, setFormOpen] = React.useState(false)
  const handleChange = (event) => {
    setQuery({ ...query, [event.target.name]: event.target.value });
  };

  const handleSearchChange = (event) => {
    setFilter(event.target.value);
  };

  const error = useFormError();
  const notify = useNotify()
  const fetchData = async (query) => {
    setOnuData([]);
    loading.setLoading(true);
    api.post('provisioning/olt/onu/all', query).then(res => 
     {
      if (res.data.error) {
        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
        return
        }
        if (res.data.errors) {
          error.setErrors(res.data.errors)
          return
          }
          error.setErrors([])
      setOnuData(res.data)}
    ).finally(() => {
      loading.setLoading(false);
    })

  };

  const filterData = (client) => {
    if (filter === "") {
      return client;
    } else if (
      client.description
        .includes(filter) ||
      client.sn.includes(filter) ||
      client.ont_id.toString().includes(filter)
    ) {
      return client;
    }
  }
  const handleSearch = (sn) => {
    setQuerySignal(prev => ({ ...prev, olt: query.olt, sn: sn }))
    setFormOpen(true)

  }
  return (
    <>
      <OnuSearchDetail
        open={formOpen}
        sn={querySignal.sn}
        olt={querySignal.olt}
        handlerClose={() => setFormOpen(false)}
      />
      <Paper
        display={"flex"}
        sx={{ bgcolor: grey[100], padding: 2 }}
      >
        <Box display={"flex"} flexDirection={"column"} width={"100%"} justifyContent={"space-around"}>
          <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} flexWrap={"wrap"}>
            <Box display={"flex"} flexWrap={'wrap'} flexDirection={"row"} justifyContent={"flex-start"}>
              <OltSelect
                size="small"
                sx={{
                  m: 1, width: { xs: 300, sm: 300, md: 400 },
                  flexGrow: { xs: 2, sm: 2, md: 0 },
                }}
                value={query.olt}
                handler={(e) => setQuery({ ...query, olt: e })}
                admin={false}
              />
              <CustomTextField
                size="small"
                sx={{
                  m: 1, width: { xs: 300, sm: 300, md: 100 },
                  flexGrow: { xs: 1, sm: 1, md: 0 },
                }}
                error={error.verify('board')}
                helperText={error.msgError('board')}
                label='Placa'
                value={query.board}
                name={'board'}
                onChange={handleChange}
                type="number"
              />
              <CustomTextField
                size="small"
                sx={{
                  width: { xs: 300, sm: 300, md: 100 },
                  flexGrow: { xs: 1, sm: 1, md: 0 },
                  m: 1
                }}
                error={error.verify('port')}
                helperText={error.msgError('port')}
                label='Porta'
                value={query.port}
                name={'port'}
                onChange={handleChange}
                type="number"
              />
              <Button
                size="small"
                sx={{
                  m: 1, display: "flex",
                  width: { xs: 300, sm: 300, md: 100 },
                  height: 40,
                  flexGrow: { xs: 1, sm: 1, md: 0 },

                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  fetchData(query);
                }}
                disabled={query.olt === "" || query.board === "" || query.port === ""}
              >
                Buscar
              </Button>
              <CustomTextField
                sx={{
                  width: { xs: 300, sm: 300, md: 200 },
                  flexGrow: { xs: 2, sm: 2, md: 1 },
                  m: 1
                }}
                label="Filtrar"
                size="small"
                value={filter}
                onChange={handleSearchChange}
                variant="outlined"
                disabled={onuData.length === 0}

              />

            </Box>
            <Box display={"flex"} alignItems={"center"} >
              {onuData.length > 0 && <>
                <Typography variant="h6" component="div" sx={{ ml: 2, mr: 1, color: "#4CAF50" }}>online:
                  {
                    " " + onuData.filter(filterData).filter((client) => client.run_state === "online").length
                  }
                </Typography>
                <Typography variant="h6" component="div" sx={{ ml: 2, color: "#F44336" }}>offline:
                  {
                    " " + onuData.filter(filterData).filter((client) => client.run_state === "offline").length
                  }
                </Typography>
                <Typography variant="h6" component="div" sx={{ ml: 2, }}>total:
                  {
                    " " + onuData.length
                  }
                </Typography>
              </>
              }
            </Box>
          </Box>
          <Divider variant="middle" sx={{ mt: 2, mb: 1 }} />
          <Box
            direction={"row"} flexWrap={"wrap"} justifyContent={'flex-start'} alignContent={'flex-start'}>
            {onuData
              .filter(filterData)
              .map((client) => (
                <OnuItem
                  key={client.ont_id}
                  id={client.ont_id}
                  description={client.description}
                  sn={client.sn}
                  state={client.run_state}
                  handler={handleSearch}
                />
              ))}

          </Box>
        </Box>
      </Paper>
    </>

  );
}



function OnuSearchBySn() {
  const [query, setQuery] = React.useState({
    sn: "",
    olt: "",
  })
  const error = useFormError()
  const handleChange = (event) => {
    setQuery({ ...query, [event.target.name]: event.target.value });
  };
  const [formOpen, setFormOpen] = React.useState(false)


  return (
    <ThemeProvider theme={theme}>
      <OnuSearchDetail
        open={formOpen}
        sn={query.sn}
        olt={query.olt}
        handlerClose={() => setFormOpen(false)}
      />
      <Paper display={"flex"}
        sx={{
          bgcolor: grey[100], padding: 2,
          flexDirection: "column",


        }}>
        <Box display={'flex'} flexDirection={{ sm: "row", md: "collumn" }} flexWrap={"wrap"}>

          <OltSelect
            size="small"
            sx={{
              ml: 2, mb: 1, mt: 1,
              flexGrow: { xs: 2, sm: 2, md: 0 },
              width: { xs: 300, sm: 300, md: 400 }
            }}
            value={query.olt}
            handler={(e) => setQuery({ ...query, olt: e })}
            admin={false}
          />
          <CustomTextField
            size="small"
            sx={{
              width: { xs: 300, sm: 300, md: 400 },
              flexGrow: { xs: 2, sm: 2, md: 0 },
              ml: 2, mb: 1, mt: 1
            }}
            error={error.verify('sn')}
            helperText={error.msgError('sn')}
            label='SN'
            value={query.sn}
            name={'sn'}
            onChange={handleChange}

          />
          <Button

            size="small"
            sx={{
              ml: 2, mt: 1, display: "flex",
              width: { xs: 300, sm: 300, md: 100 },
              height: 40,
              flexGrow: { xs: 1, sm: 1, md: 0 },

            }}
            variant="contained"
            color="primary"
            onClick={() => {
              setFormOpen(true);
            }}
            disabled={query.olt === "" || query.sn === ""}
          >
            Buscar
          </Button>
        </Box>
      </Paper>
    </ThemeProvider>
  )
}