import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, ThemeProvider, createTheme } from "@mui/material"
import CustomTextField from "../CustomTextField"
import theme from "../../themeContent"
import { useEffect, useState } from "react"
import { useFormError } from "./FormErrorProvider"
import { red } from "@mui/material/colors"

export default function FormDialog({ open, title, handlerClose = () => { }, handlerSubmit = () => { }, children = <></>, btnSubmit = "Salvar", btnClose = "Fechar", isLoaded = true, maxWidth = 'sm' }) {
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const { errors, setErrors } = useFormError()
    useEffect(() => {
        if (errors.length > 0) {
            setLoadingSubmit(false)
        }
    }, [errors])
    useEffect(() => {
            setLoadingSubmit(false)
    },[open])

    return (
        <ThemeProvider theme={theme}>

            <Dialog open={open} onClose={handlerClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={maxWidth}


            >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={!isLoaded}

                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    {children}





                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ display: btnClose == false && typeof btnClose === 'boolean' ? 'none' : 'block' }}
                        onClick={handlerClose} variant="outlined" color="primary">
                        {btnClose}
                    </Button>
                    <Button
                        disabled={loadingSubmit}
                        onClick={() => {
                            setErrors([])
                            setLoadingSubmit(true)
                            handlerSubmit()
                        }}
                        variant="contained"
                        sx={{ display: btnSubmit == false && typeof btnSubmit == 'boolean' ? 'none' : 'block' ,
                            maxHeight: 36,
                        }}
                    >

                        {loadingSubmit ? <CircularProgress color="info" size="1rem" thickness={7} sx={{mt:.5, color:red[700]}}  /> : btnSubmit}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>

    )
}


export function Text({ label, name, value, onChange, type = "text", props = {}, multiline = false, rows = 1, required = false }) {
    return (
        <CustomTextField
            required={required}
            multiline={multiline}
            rows={rows}
            autoFocus
            name={name}
            margin="dense"
            id={label}
            label={label}
            type={type}
            fullWidth
            value={value}
            onChange={onChange}
            {...props}
        />
    )
}

export function Select({ label, value, onChange, size, options = [], required = false, props = {}, multiple = false, placeholder = "Selecione uma opção" }) {
    return (
        <Autocomplete
            multiple={multiple}
            id="tags-standard"
            size={size}
            options={options}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
                <CustomTextField
                    {...params}
                    variant="standard"
                    label={label}
                    placeholder="Favorites"
                />
            )}
        />
    )
}
