import React, { useEffect, useState } from 'react';
import Datatable from '../../../components/Datatable';
import FormDialog, { Text } from '../../../components/Datatable/FormDialog';
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../../components/Datatable/NotifyProvider';
import { useFormError } from '../../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import 'moment/locale/pt-br'
import { useAuth } from '../../../components/AuthAdminProvider';import CustomTextField from '../../../components/CustomTextField';
``

export default function User() {
    const auth = useAuth()
    const notify = useNotify()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })

    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Olimpo - Usuários"
    }, [])
    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
               handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Usuários'
                endpoint='/admin/user'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <span>
                                <IconButton onClick={() => { localStorage.removeItem('alarms'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Adicionar">
                                <span>
                                <IconButton disabled={!auth.hasPerm('user.create')}
                                    onClick={() => setFormState({ open: true, id: null })}
                                    >
                                    <AddIcon />
                                </IconButton>
                                </span>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'name',
                        label: 'Nome',
                    },
                    {
                        name: 'username',
                        label: 'Nome do usuário',
                    },
                    {
                        name: 'role',
                        label: "Cargo"
                    },
                    {
                        name: 'email',
                        label: 'E-mail',
                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'updated_at',
                        label: 'Atualizado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },

                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton disabled={!auth.hasPerm('user.update')}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()
    const [roles, setRoles] = React.useState([])
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {name: '',
    username: '',
    role: 0,
    email: '',
    password: '',
    active: true,
    }
    const [state, setState] = useState(modelState)

    const handleChangeCheck = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked
        });
    };
    const getRoles = () => {
        api.get('/admin/role/all/').then(res => {
            setRoles(res.data)

        }).catch(err =>
            setRoles([])
        )

            .catch(err => console.log(err))
    }
    useEffect(() => {
        setState(modelState)
        if (!open) return
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/admin/user', id).then(res => {
                setState(res.data)
                setIsLoaded(true)
            }).catch(e => {console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id,open])

    useEffect(() => {
        setState(modelState)
        if (open) {
            getRoles()
        }

    }, [open])

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {
        if (id) {
            update('/admin/user', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.error){
                    error.setErrors([
                        {field: 'global', msg: res.data.error}
                    ])
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return   
                }
                   if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Usuário atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/admin/user/create', state)
                .then(res => {
                    if (res.data.error){
                    error.setErrors([
                        {field: 'global', msg: res.data.error}
                    ])
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return   
                }
                   if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Usuário criado com sucesso.', severity: 'success' })
                    error.setErrors([])
                })
                .catch(e =>
                    notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Usuário</Typography>
                        <IconButton disabled={!auth.hasPerm("user.delete")} 
                        sx={{display:id? 'block':'none'}}
                        onClick={() => {
                            if (confirm('Deseja excluir o usuário?')) {
                                delete_one('/admin/user', id).then(res => {
                                    notify.setNotify({ open: true, message: 'Usuário deletado com sucesso.', severity: 'success' })
                                    handlerClose()
                                    handlerSubmit()
                                })
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                    <CustomTextField
                        required
                        sx={{ mb: 2 }}
                        error={error.verify('name')}
                        helperText={error.msgError('name')}
                        label='Nome'
                        value={state.name || ''}
                        name={'name'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        required
                        sx={{ mb: 2 }}
                        error={error.verify('username')}
                        helperText={error.msgError('username')}
                        label='Usuário'
                        value={state.username || ''}
                        name={'username'}
                        onChange={handleChange}
                    />
                    <FormControl fullWidth>
                        <InputLabel  id="demo-simple-select-label" required>Cargo</InputLabel>
                        <Select
                            size='small'
                            labelId="demo-simple-select-label"
                            id="role"
                            value={state.role || 0}
                            label="Cargo"
                            onChange={handleChange}
                            name='role'
                            error={error.verify('role')}
                            
                        >
                            {
                                roles.map((role, index) => (
                                    <MenuItem key={index} value={role.id}>{role.description}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <CustomTextField
                        required
                        sx={{ mb: 2, mt: 2 }}
                        error={error.verify('email')}
                        helperText={error.msgError('email')}
                        label='Email'
                        value={state.email || ''}
                        name={'email'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        required
                        sx={{ mb: 2}}
                        error={error.verify('password')}
                        helperText={error.msgError('password')}
                        label='Senha'
                        value={state.password || ''}
                        name={'password'}
                        onChange={handleChange}
                    />
                    <FormControlLabel sx={{ ml: 1, mt: 1 }}
                        control={
                            <Checkbox checked={state.active || false} onChange={handleChangeCheck} name="active"
                            />
                        }
                        label="Ativo"
                    />





                </Box>

            </FormDialog>
        </Box>
    )
}


