import React, { useEffect, useState } from 'react';
import Datatable from '../../../components/Datatable';
import FormDialog from '../../../components/Datatable/FormDialog';
import { Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../../components/Datatable/NotifyProvider';
import { useFormError } from '../../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../../components/AuthAdminProvider';
import moment from 'moment';
import EmpresaSelect from '../../../components/EmpresaSelect';
import CustomTextField from '../../../components/CustomTextField';

export default function ProvService() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [ refresh, setRefresh ] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Olimpo - Planos de Backup"
      }, [])
    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
               handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={()=>setRefresh(!refresh)}
                title='Planos de Backup'
                endpoint='/admin/hermes/service'
                options={{
                    customToolbar: () =>
                        <>
                            
                            <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm('service.create')}
                                    onClick={() => setFormState({ open: true, id: null })} 
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'empresa_name',
                        label: 'Empresa',
                    },
                    {
                        name: "description",
                        label: "Nome",
                    },
                    {
                        name: 'active',
                        label: 'Ativo',
                        options:{
                            customBodyRender: (value) => value==1 ? 'Sim' : 'Não'
                        }
                    },
                    {
                        name: 'hosts_limit',
                        label: 'Qtde de Hosts',
                        options: {
                            customBodyRender: value => <span>{value == -1 ? "Ilimitado" : value}</span>,
                        }
                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'updated_at',
                        label: 'Atualizado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton disabled={!auth.hasPerm('service.update')}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar'}) {
    const notify = useNotify()
    const error = useFormError()
    const [permissions, setPermissions] = React.useState([])
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {description: '',
    active: 1,
    hosts_limit: 0,
    obs: '',
    empresa: null,
    }
    const [state, setState] = useState(modelState)
  
    useEffect(() => {
        setState(modelState)
        if (!open) return
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/admin/hermes/service', id).then(res => {
                setState(res.data.data)
                setIsLoaded(true)

            }).catch(e => {console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id,open])

 

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {

        if (id) {
            update('/admin/hermes/service', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.error){
                    error.setErrors([
                        {field: 'global', msg: res.data.error}
                    ])
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return   
                }
                   if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Serviço atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/admin/hermes/service/create', state)
            .then(res => {
                if (res.data.error){
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return
                }
                if (res.data.errors) {
                    error.setErrors(res.data.errors)
                    return
                }
                handlerSubmit()
                notify.setNotify({ open: true, message: 'Serviço criado com sucesso.', severity: 'success' })
                error.setErrors([])
            })
            .catch(e => 
                notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

        <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Serviço</Typography>
                        <IconButton disabled={!auth.hasPerm("service.delete")} 
                        sx={{display:id? 'block':'none'}}
                        onClick={()=>{
                             if (confirm('Deseja excluir o serviço?')){
                                    delete_one('/admin/hermes/service', id).then(res => {
                                        notify.setNotify({ open: true, message: 'Serviço deletado com sucesso.', severity: 'success' })
                                        handlerClose()
                                        handlerSubmit()
                                    })
                             }
                        }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <EmpresaSelect 
                    sx={{ mt: 1}}
                    value={state.empresa}
                    handler={(e) => setState({...state, empresa: e})}
                    disabled={id}
                    />
                    <CustomTextField
                        sx={{ mt: 2, mb: 2 }}
                        error={error.verify('description')}
                        helperText={error.msgError('description')}
                        label='Descrição'
                        value={state?.description }
                        name={'description'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2}}
                        error={error.verify('hosts_limit')}
                        helperText={error.msgError('hosts_limit')}
                        label='Qtde de Hosts'
                        value={state?.hosts_limit }
                        name={'hosts_limit'}
                        onChange={handleChange}
                    />
                     <CustomTextField
                        sx={{ mb: 2}}
                        multiline
                        minRows={3}
                        error={error.verify('obs')}
                        helperText={error.msgError('obs')}
                        label='Observação'
                        value={state?.obs }
                        name={'obs'}
                        onChange={handleChange} />
                    <FormGroup>
                    <FormControlLabel control={
                        <Checkbox 
                        checked={state?.active}
                        onChange={(e) => setState({...state, active: e.target.checked ? 1 : 0})}
                        />
                    }
                    label="Ativo"
                    />
                    </FormGroup>
                
                   
                </Box>

            </FormDialog>
        </Box>
    )
}
