import React, {useEffect, useState} from 'react'
import FormDialog from '../../../components/Datatable/FormDialog'
import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import Datatable from '../../../components/Datatable'
import { RemoveCircleOutline } from '@mui/icons-material'
import { useAuth } from '../../../components/AuthProvider'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNotify } from '../../../components/Datatable/NotifyProvider'
import { useFormError } from '../../../components/Datatable/FormErrorProvider'
import { create, delete_one, get_one, update } from '../../../services/api'
import CustomTextField from '../../../components/CustomTextField'

export default function FormOltAccess({open, olt, empresa,oltName, ...props}) {
  return (
    <FormDialog open={open} {...props}
        title={''}
        btnSubmit={false}
        maxWidth='lg'
    >
        <Access olt={olt}  empresa={empresa} oltName={oltName}/>    
    </FormDialog>
    
  )
}

const Access = ({olt, empresa, oltName})=>{
    const auth = useAuth()
    const [refresh, setRefresh] = useState(false)
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    return(
        <>
        <FormItem
            olt={olt}
            empresa={empresa}
            id={formState.id}
            open={formState.open}
            handlerClose={() => setFormState({ open: false, id: null })}
            handlerSubmit={() => {
                setFormState({ open: false, id: null })
                setRefresh(true)
            }}  />
       <Datatable
        refresh = {refresh}
        handlerRefresh={()=>setRefresh(!refresh)}
        title={`Acessos - ${oltName}`}
        endpoint={`provisioning/olt/access/all/${olt}`}
        options={{
            customToolbar: () =>
                <>
                    <Tooltip title="Remover todos os filtros">
                        <IconButton onClick={() => { localStorage.removeItem('alarms'); location.reload() }} >
                            <RemoveCircleOutline />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Adicionar">
                        <IconButton disabled={!auth.hasPerm('olt_access.create')}
                            onClick={() => setFormState({ open: true, id: null })}
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ,
        }}
        columns={[
           { name: 'id', label: 'ID', options: { display: false } },

           
           { name: 'username', label: 'Usuário' },
           { name: 'password', label: 'Senha' },
           { name: "max_access", label: "Máximo de acessos" },
              
              {
                  name: 'actions',
                label: 'Ações',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <>
                                <Tooltip title="Editar">
                                    <IconButton disabled={!auth.hasPerm('olt_access.update')}
                                        onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                    >
                                        <EditIcon />

                                    </IconButton>
                                </Tooltip>

                            </>
                        )
                    }
                }
            }
        ]}
       />
        </>

       

    )
}

function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar', olt, empresa }) {
    const notify = useNotify()
    const error = useFormError()
    const [permissions, setPermissions] = React.useState([])
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {
        username: '',
        password: '',
        max_access: 0,
    }
    const [state, setState] = useState(modelState)

    useEffect(() => {
        if (!open) return
        setState(modelState)
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/provisioning/olt/access', id).then(res => {

                setState(res.data.data)
                setIsLoaded(true)

            }).catch(e => {
                console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id,open])



    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {
        if (id) {
            update('/provisioning/olt/access', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.error){
                    error.setErrors([
                        {field: 'global', msg: res.data.error}
                    ])
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return   
                }
                   if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Acesso atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
           
            create('/provisioning/olt/access/create', {...state, olt: olt, empresa: empresa})
                .then(res => {
                    if (res.data.error){
                    error.setErrors([
                        {field: 'global', msg: res.data.error}
                    ])
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return   
                }
                   if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Acesso criado com sucesso.', severity: 'success' })
                    error.setErrors([])
                })
                .catch(e =>
                    notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Acesso</Typography>
                        <IconButton disabled={!auth.hasPerm("olt_access.delete")} onClick={() => {
                            if (confirm('Deseja excluir o item?')) {
                                delete_one('/provisioning/olt/access', id).then(res => {
                                    notify.setNotify({ open: true, message: 'Acesso deletado com sucesso.', severity: 'success' })
                                    handlerClose()
                                    handlerSubmit()
                                })
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CustomTextField
                        sx={{ mb: 2, mt: 2 }}
                        error={error.verify('username')}
                        helperText={error.msgError('username')}
                        label='Usuario'
                        value={state.username}
                        name={'username'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('password')}
                        helperText={error.msgError('password')}
                        label='Senha'
                        value={state.password}
                        name={'password'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('max_access')}
                        helperText={error.msgError('max_access')}
                        label='Maximo de acessos'
                        value={state.max_access}
                        name={'max_access'}
                        onChange={handleChange}
                    />
                    
                </Box>

            </FormDialog>
        </Box>
    )
}
