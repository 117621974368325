import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";


let LoadingContext = React.createContext(null);

export function LoadingProvider({ children }) {
    let [loading, setLoading] = React.useState(false);
    
    return (
        <LoadingContext.Provider value={{ loading, setLoading }}>
        {children}
        {loading && (<Backdrop 
         sx={{ color: '#fff', zIndex: 99999 }}
         open={loading}>
                        <CircularProgress />
                    </Backdrop>
        )}
        </LoadingContext.Provider>
    );
}

export function useLoading() {
    return React.useContext(LoadingContext);
}