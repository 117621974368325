// Remove the unused import statement
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';

import NotifyProvider from './components/Datatable/NotifyProvider';
import FormErrorProvider from './components/Datatable/FormErrorProvider';
import { AuthProvider, RequireAuth } from './components/AuthProvider';
import { AuthProviderAdmin } from './components/AuthAdminProvider';
import globalRouter from './components/globalRouter';
import { LoadingProvider } from './components/LoadingProvider';
import { SearchProvider } from './components/SearchProvider';

import React from 'react';
import Router from './routes'


export default function App() {
  return (

    <BrowserRouter >
      <ProviderBundle>
        <AuthProviderAdmin>
          <FormErrorProvider>
            <AuthProvider>
              <Router />
            </AuthProvider>
          </FormErrorProvider>
        </AuthProviderAdmin>
      </ProviderBundle>
    </BrowserRouter>





  );
}
const ProviderBundle = ({ children }) => {
  return (
    <SearchProvider>
      <LoadingProvider>
        <FormErrorProvider>
          <NotifyProvider>
            {children}
          </NotifyProvider>
        </FormErrorProvider>
      </LoadingProvider>
    </SearchProvider>
  );
}




