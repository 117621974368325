import React, { useEffect, useState } from 'react'
import api from '../../../services/api'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, createTheme } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ThemeProvider } from '@emotion/react'
import { useLoading } from '../../../components/LoadingProvider';
import { useNotify } from '../../../components/Datatable/NotifyProvider';
import { NetworkCheck } from '@mui/icons-material';
import { useAuth } from "../../../components/AuthProvider"
import theme from '../../../themeContent';


export default function OnuSearchDetail({ sn, olt, open, handlerClose }) {
    const [data, setData] = useState()
    const loading = useLoading()
    const notify = useNotify()
    function fetchData() {
        loading.setLoading(true)
        api.post("provisioning/olt/onu/signal", { olt, sn }).then(res => {
            setData(res.data)
        }).finally(() => loading.setLoading(false))
    }

    useEffect(() => {
        setData({})
        if (open && sn && olt) {
            fetchData()
        }
    }, [open])

    return (
        <ThemeProvider theme={theme}>

            <Dialog open={open} onClose={handlerClose} aria-labelledby="form-dialog-title" fullWidth >
                <DialogTitle id="form-dialog-title">Detalhes Cliente {data?.Description}</DialogTitle>
                <DialogContent>
                    {data && <TableContainer>
                        <Table>

                            <TableHead disableToolbarSelect>
                                <TableRow>
                                    <TableCell>Propriedade</TableCell>
                                    <TableCell>Valor</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data && Object.entries(data).map(line => <TableRow>
                                    <TableCell>{line[0]}</TableCell>
                                    <TableCell>{line[1]}</TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer> || "SN não encontrado."}


                </DialogContent>
                <DialogActions>
                    <Button

                        onClick={handlerClose} variant="outlined" color="primary">
                        Fechar
                    </Button>

                </DialogActions>
            </Dialog>
        </ThemeProvider>

    )

}

export const ONUSearchDetailForm = ({ olt, sn }) => {
    const auth = useAuth()
    const [open, setOpen] = useState(false)
    
    return (
        <>
            <OnuSearchDetail
                open={open}
                sn={sn}
                olt={olt}
                handlerClose={()=> setOpen(false)}
            />
            <IconButton disabled={!auth.hasPerm('onu.get_signal')}
                onClick={() => setOpen(true)}
            >
                <NetworkCheck />

            </IconButton>
        </>
    )
}