import { Routes, Route } from "react-router-dom";
import useAdminRoutes from "./admin";
import useProvRoutes from "./provisioning";
import useHermesRoutes from "./hermes";

import LoginPage from '../pages/provisioning/login/LoginPage';
import LoginAdminPage from '../pages/admin/login/LoginPage';
import NotFound from '../pages/NotFound';


export default function () {
    const ProvisioningRoutes = useProvRoutes();
    const AdminRoutes = useAdminRoutes();
    const HermesRoutes = useHermesRoutes();
    return (

        <Routes>
            {HermesRoutes}
            {ProvisioningRoutes}
            {AdminRoutes}
            <Route path="/login" element={<LoginPage />} /> 
            <Route path="/admin/login" element={<LoginAdminPage />} />        
            <Route path="*" element={<NotFound />} />
        </Routes>
    )

}

