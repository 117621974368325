import { css, IconButton, Popper, styled, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useCallback, useEffect } from "react";

export default function PopperItem({ name, icon, children }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' + "name" : undefined
    const ref = React.useRef(null)
    
   

 
    const handleClickOutside = useCallback(e=>{
        if (ref.current && !ref.current.contains(e.target)) {
            setAnchorEl(null);
        }
    }, [ref, setAnchorEl]);

    useEffect(() => {

        document.addEventListener('mouseup', handleClickOutside);
        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [ref, setAnchorEl]);

    return (
        <>
       
                <IconButton onClick={handleClick}>
                    {icon}
                </IconButton>
          
            <Popper style={{zIndex: 10000}} ref={ref} disablePortal  id={id} open={open} anchorEl={anchorEl}
            onClose={()=>console.log("oi")} placement="bottom-start"
            >
                <StyledPopperDiv>{children}</StyledPopperDiv>

            </Popper>
        </>
    )
}



const StyledPopperDiv = styled('div')(
    ({ theme }) => css`
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 4px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: ${theme.palette.mode === 'dark'
            ? `0px 4px 8px rgb(0 0 0 / 0.7)`
            : `0px 4px 8px rgb(0 0 0 / 0.1)`};
    padding: 0.25rem;
    color: ${theme.palette.mode === 'dark' ? grey[100] : grey[700]};
    font-size: 0.875rem;
    font-weight: 500;
    opacity: 1;
    margin: 0.25rem 0.25rem;
    
  `,
);