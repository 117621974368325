import React, { useCallback, useRef, useState } from 'react'
import { CircularProgress, FormControl, TextField, ThemeProvider, Typography, createTheme } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api, { get_all } from '../services/api'
import { ConnectingAirportsOutlined } from '@mui/icons-material'
import { useFormError } from './Datatable/FormErrorProvider'

export default function SearchMap({handler , ...props }) {
    const [data, setData] = useState([])
    const [search, setSearch] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [value, setValue] = React.useState(null)

    const [keyChange, setkey] = React.useState(false)
   
    React.useLayoutEffect(() => {
      let timeoutId;
      if (search.length>= 3 ){
        timeoutId = setTimeout(()=>fetchData(),500)
      }
      return ()=>clearTimeout(timeoutId)
      
      }, [search])

    const fetchData = ()=>{
      setLoading(true)
      setData([])
      api.post("provisioning/onu/map/search", {query: search}).then(res=>{
        setData(res.data)
        }).finally(()=>setLoading(false))
    }
  return (
    <ThemeProvider
    theme={createTheme({
      typography:{
          fontFamily:[
            'sans-serif',
            'Roboto',
            'Montserrat',
          ]
        },
        palette: {
          mode: 'light',
          primary: {
            main: "#e01b29",
          },
          }
  })}>

      <FormControl 
      {...props}
      key={keyChange}
      >
      <Autocomplete
      size='small'
      id="data-outlined"
      options={data}
      onChange={(event, newValue) => {
        setkey(!keyChange)
        handler([newValue.lat, newValue.long])
        //setData([])
        //setSearch("")
      }}
      key={(option) => option.sn}
      isOptionEqualToValue={(option, value) => value.description == option.description}
      getOptionLabel={(option) => option.description  }
      value={value}
      loadingText={"Buscando..."}
      noOptionsText={"Sem opções"}
      filterSelectedOptions
      loading={loading}
      renderInput={(params) => (
        <TextField
        
        onChange={e=> setSearch(e.target.value)}
        {...params}
        size={"small"}
        label="Buscar Cliente/CTO"
        />
      )}
      />
      </FormControl>
      </ThemeProvider>
   
    )
  }
  