import { Container, Grid, ListItemButton, ListItemIcon, ListItemText, Paper, ThemeProvider, Typography } from '@mui/material'
import React from 'react'
import { MiscellaneousServices } from '@mui/icons-material'
import FormDialog from './FormDialog'
import { grey } from '@mui/material/colors'
import { IconButton, Tooltip } from '@mui/material';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useAuth } from '../../../components/AuthProvider';

export default function (){
    const [open, setOpen] = React.useState(false);
    const auth = useAuth()
    return(

     <>
        <FormDialog open={open} handlerClose={()=>setOpen(false)}/>
        <Tooltip title="Executar importação de hosts">
            <IconButton disabled={!auth.hasPerm('host.create')}
                onClick={() => {setOpen(true)
                }}
            >
                <PlaylistAddIcon />
            </IconButton>
        </Tooltip>
     </>


    )
}