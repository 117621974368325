import React from 'react';
import { Box, Tooltip, IconButton, List, ListItem, ListItemText, ListItemButton, Typography } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import PersonIcon from '@mui/icons-material/Person';
import { grey } from '@mui/material/colors';
import Popover from './Popover';
import { Logout, Person, Settings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function Navbar({ username, handlerLogout, admin }) {
    return (
        
        <>
           {/* { !admin && <ServicesPopper />} */}
            <Tooltip title="Sair" >
                    <IconButton sx={{ color: grey[100] }}
                        onClick={handlerLogout}
                    >
                        <Logout />
                    </IconButton>
                </Tooltip>
            <Typography >{username}</Typography>
        </>
    )
}

function ServicesPopper() {
    const navigate = useNavigate()
    return (
        <Popover name="Serviços" icon={<AppsIcon sx={{ color: grey[100] }} />}>
            <Box>
                <Tooltip title="Provisionamento" >
                    <IconButton
                        onClick={() => navigate('/provisioning/dashboard')}
                    >
                        <PersonIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </Popover>
    )
}
function SettingsPopper({ handlerLogout, username }) {
    return (
        <Popover name={username} icon={<Settings sx={{ color: grey[100] }} />}>
          <Tooltip title="Sair" >
                    <IconButton
                        onClick={handlerLogout}
                    >
                        <Logout />
                    </IconButton>
                </Tooltip>
        </Popover>
    )
}