import  { useEffect, useState } from 'react';
import api from '../../../services/api';
import Card from '../../../components/Card';



export default function TotalHosts() {
  const [data, setData] = useState(0)
  useEffect(() => {
    api.get("hermes/dashboard/host/all").then((res) => {
        setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  },[])
    return (
    <Card title={data} subtitle="Hosts"/>
  );
}