import  { useEffect, useState } from 'react';
import api from '../../../services/api';
import Card from '../../../components/Card'


export default function () {
  const [data, setData] = useState(0)
  useEffect(() => {
    api.get("hermes/dashboard/host/active").then((res) => {
        setData(res.data)
},[]
)})
    return (
    <Card title={data} subtitle="Hosts ativos"/>
  );
}