import React, { useState } from 'react'
import { FormControl, TextField, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api from '../services/api'
import { ConnectingAirportsOutlined } from '@mui/icons-material'
import { useFormError } from './Datatable/FormErrorProvider'
import CustomTextField from './CustomTextField'

export default function ProfileSelect({ handler, empresa_id, admin = true, size = "medium", disabled, ...props }) {
  const [profiles, setProfiles] = useState([])
  const [profile, setProfile] = useState(null)
  const error = useFormError()
  const [loading, setLoading] = React.useState(true)
  React.useLayoutEffect(() => {
    setLoading(true)
    api.get(`provisioning/profile/select/all`).then(res => {
      setProfiles(res.data)
    }).finally(() => setLoading(false))
  }, [])

  return (
    profiles.length > 0 ?
      <FormControl
        {...props}
      >
        <Autocomplete
          loadingText={"Buscando..."}
          noOptionsText={"Sem opções"}
          loading={loading}
          fullWidth
          disabled={disabled}
          id=" profiles-outlined"
          options={profiles}
          onChange={(event, newValue) => {
            setProfile(newValue)
            handler(newValue?.id)
          }}
          key={(option) => option.id}

          isOptionEqualToValue={(option, value) => value?.id && option.id == value.id}
          getOptionLabel={(option) => option.description}
          value={profile}
          filterSelectedOptions
          renderInput={(params) => (
            <CustomTextField

              error={error.verify('profile')}
              helperText={error.msgError('profile')}
              {...params}
              size={size}
              label="Templates de Provisionamento"
            />
          )}
        />
      </FormControl> :
      <CustomTextField
        {...props}
        error={error.verify('profile')}
        helperText={error.msgError('olt')}
        label={admin && !empresa_id ? "Selecione uma empresa" : "Nenhuma CTO foi cadastrada"}
        disabled={true}
      />

  )
}