import React, { useEffect } from 'react';
import { useLocation, useNavigate, Navigate, redirect } from 'react-router-dom';

import api from '../services/api';
import { Box, Container } from '@mui/material';
import LoadingPage from './LoadingPage';
import { pickersMonthClasses } from '@mui/x-date-pickers';


let AuthContext = React.createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = React.useState();
  const [perms, setPerm] = React.useState([]);
  const [name, setName] = React.useState();
  const [logout, setLogout] = React.useState(false); // Variável de controle para forçar a validação do token após o logout
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [services, setServices] = React.useState([]);
  const [service, setService] = React.useState(false);
 
  
  
  React.useEffect(() => {
    if (location.pathname.startsWith("/admin")){
      setLoading(false)
      return
    }
    if (validateToken()) {
      getUser();
    }else{
      setLoading(false)
      //navigate('/login')
    }
  }, []); 

  const TOKEN_KEY = 'tokenClient';
  function getToken() {
    const cookieValue = document.cookie
      .split(';')
      .map((row) => row.trim())
      .find((row) => row.startsWith(`${TOKEN_KEY}=`))
      ?.split('=')[1];
    return cookieValue;
  }

  function setToken(tk) {
    let now = new Date();
    const time = now.getTime();
    const expireTime = time + 1000 * 60 * 30 * 48;
    now.setTime(expireTime);
    document.cookie = `${TOKEN_KEY}=${tk}; expires=${now.toUTCString()}; SameSite=Strict `;
    api.defaults.headers.common['Authorization'] = tk;
  }

  function deleteCookies() {
    var allCookies = document.cookie.split(';');

    // The "expire" attribute of every cookie is
    // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
    //maintain cookies but remove token key
    for (var i = 0; i < allCookies.length; i++){
      if(allCookies[i].includes(TOKEN_KEY)){ 
        document.cookie = allCookies[i] + '=;expires=' + new Date(0).toUTCString();
      }
    }
  }
    

  function validateToken() {
    if (getToken() == undefined || getToken() == "") {
      setUser(null);
      return false;

    }
    setToken(getToken());
    return true;
  }
  function getUser(useLoading=false){
    if(useLoading){
      setLoading(true)
    }
    api
      .get('/auth/me/')
      .then((res) => {
        setUser(res.data.username);
        setName(res.data.name);
        setPerm(res.data.permissions.map((perm) => perm.permission));
        setServices(Array.from(new Set(res.data.permissions.map((perm) => perm.service))));

      })
      .catch((err) => {
        deleteCookies();
        setUser(null);
        setName(null);
        setPerm([]);
        setServices([]);
        

         // navigate('/login')   
      }).finally(()=>{
        setLoading(false)
      });
  }

  const hasPerm = (p) => perms.some((perm) => perm === p);
  const hasService = (s) => services.some((service) => service === s);

  const signin = (cred, callback, clerr) => {
    api
      .post('/auth/token/', cred)
      .then((res) => {
        setUser(res.data.username);
        const token = `Bearer ${res.data.access_token}`;
        setToken(token);
        getUser(true);
        callback();
        return true;
      })
      .catch((err) => {
        clerr();
        return false;
      });



  };

  const refreshPerms = ((useLoading=false)=>{
    getUser(useLoading)
  })

  const signout = (callback = () => {}) => {
    setUser(null);
    deleteCookies();
    if (service) {
      navigate(service);
      
    }else{
      navigate('/login')
    }
    callback();
  };

  let value = { user,name, validateToken, signin, signout, hasPerm, getToken, refreshPerms, hasService, setService, service };

  return <AuthContext.Provider value={value}>
    {
   loading ?   <LoadingPage loading={loading} />  : children
  }
  </AuthContext.Provider>;
}



export function RequireAuth({ service, children }) {
  let auth = useAuth();
  
  let location = useLocation();
  if (auth?.user === undefined) {
    return null; // or loading indicator, etc...
  }

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (service && !auth.hasService(service)) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }else{
    auth.setService(service)
  }

  return children;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthStatus() {
  let auth = useAuth();
  let navigate = useNavigate();

  if (!auth.user) {
    return <p>You are not logged in.</p>;
  }

  return (
    <p>
      Welcome {auth.user}!{' '}
      <button
        onClick={() => {
          auth.signout(() => navigate('/'));
        }}
      >
        Sign out
      </button>
    </p>
  );
}
