import { useEffect, useState } from "react"
import { useNotify } from "../../../components/Datatable/NotifyProvider"
import api from "../../../services/api"
import { useFormError } from "../../../components/Datatable/FormErrorProvider"
import { Autocomplete, Box, FormControl, TextField } from "@mui/material"
import { useLoading } from '../../../components/LoadingProvider';
import CustomTextField from "../../../components/CustomTextField"
export default function FreeOnuSelectSimple({ cto, handlerSn, handlerSrv, handlerLine,waitOlt, handlerSubmit, submit = false, size = "small", ...props }) {
  const notify = useNotify()
  const [data, setData] = useState([])
  const error = useFormError()
  const loading = useLoading()
  const [dataForm, setDataForm] = useState({
    sn: null,

  })
  useEffect(() => {
    if (cto == "") return
    if (!submit) return
    fetchData()
    handlerSubmit()
  }, [submit])

  const fetchData = () => {
    setDataForm([])
    loading.setLoading(true)

    api.post("provisioning/olt/onu/free_simple", { cto }).then(res => {
      setData(res.data)
      if (res.data.error) {
        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
        return
        }
      if (res.data.errors) {
        error.setErrors(res.data.errors)
        return
        }
        error.setErrors([])
    }).finally(() => loading.setLoading(false))

  }

  return (
  
    <FormControl
      {...props}
    >
      <Autocomplete

        id="ctos-outlined"
        options={data}
        value={dataForm.sn}
        onChange={(event, newValue) => {
          setDataForm({ ...dataForm, sn: newValue })
          handlerSn(newValue)
        }}
        key={(option) => option.id +"sn_simple"}

        isOptionEqualToValue={(option, value) => value && option == value}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <CustomTextField
            error={error.verify('sn')}
            helperText={error.msgError('sn')}
            {...params}
            size={size}
            label="SNs"
          />

        )}
      />
      </FormControl>


  )
}