import React, { useEffect, useState } from 'react';
import Datatable from '../../components/Datatable';
import FormDialog from '../../components/Datatable/FormDialog';
import { Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import { useNotify } from '../../components/Datatable/NotifyProvider';
import { useFormError } from '../../components/Datatable/FormErrorProvider';
import { Close, Done, PlayArrow, RemoveCircleOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../components/AuthProvider';
import moment from 'moment';
import CustomTextField from '../../components/CustomTextField';
import HermesTypeSelect from '../../components/HermesTypeSelect';
import TunnelSelect from '../../components/TunnelSelect';
import BtnZabbix from './Zabbix';

export default function Host() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [formAccess, setFormAccess] = useState({
        open: false,
        id: null,
    })
    const error = useFormError()
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Hermes - Host"

        error.setErrors([])

    }, [])
    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
                handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Hosts'
                endpoint='/hermes/host'
                options={{
                    customToolbar: () =>
                        <>
                            
                            <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm('host.create')}
                                    onClick={() => setFormState({ open: true, id: null })}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                            <BtnZabbix callback={setRefresh(true)}/>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'host_name',
                        label: 'Nome',
                    },
                    {
                        name: 'ip',
                        label: 'Ip Address',
                    },
                    {
                        name: "vendor_name",
                        label: "Marca",
                    },
                    {
                        name: 'type_name',
                        label: 'Tipo',
                    },

                    {
                        name: 'status',
                        label: 'Ativo',
                        options: {
                            customBodyRender: (value) => value == 1 ?
                                <Done
                                    sx={{ color: 'green' }}
                                /> : <Close
                                    sx={{ color: 'red' }}
                                />
                        }
                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'updated_at',
                        label: 'Atualizado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton disabled={!auth.hasPerm('host.update')}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Executar backup">
                                            <IconButton disabled={!auth.hasPerm('backup.create')}
                                                onClick={() => {
                                                    api.get(`/hermes/backup/run/${tableMeta.rowData[0]}`, { host_id: tableMeta.rowData[0] }).then(res => {
                                                        notify.setNotify({ open: true, message: "Solicitação enviada com sucesso", severity: 'success' })
                                                    }
                                                    ).catch(e => {
                                                        notify.setNotify({ open: true, message: "Erro ao executar. Informe ao administrador.", severity: 'error' })
                                                    })
                                                }}

                                            >

                                                <PlayArrow />
                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {
        host_name: '',
        type_vendor: null,
        ip_address: '',
        username: '',
        password: '',
        enable_password: '',
        port: '',
        status: 1,


    }
    const [state, setState] = useState(modelState)
    const [showPassword, setShowPassword] = useState(false)
    useEffect(() => {
        if (!open) return
        setState(modelState)
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/hermes/host', id).then(res => {
                setState({
                    host_name: res.data.data.host_name,
                    type: res.data.data.type,
                    ip_address: res.data.data.ip_address,
                    username: res.data.data.username,
                    password: '********',
                    enable_password: '********',
                    port: res.data.data.port,
                    status: res.data.data.status,
                    interface: res.data.data.interface
                })
                setIsLoaded(true)
            }).catch(e => {
                console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id, open])



    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {

        if (id) {
            update('/hermes/host', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    console.log(res)
                    if (res.data.error) {
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    }
                    if (res.data.errors) {
                        console.log(res.data.errors)
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Host atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/hermes/host/create', state)
                .then(res => {
                    if (res.data.error) {
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    }
                    if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Host criado com sucesso.', severity: 'success' })
                    error.setErrors([])
                })
                .catch(e =>
                    notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Host</Typography>
                        <IconButton disabled={!auth.hasPerm("host.delete")}
                            sx={{ display: id ? 'block' : 'none' }}
                            onClick={() => {
                                if (confirm('Deseja excluir o Host?')) {
                                    delete_one('/hermes/host', id).then(res => {
                                        notify.setNotify({ open: true, message: 'Host deletado com sucesso.', severity: 'success' })
                                        handlerClose()
                                        handlerSubmit()
                                    })
                                }
                            }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }} pt={1} gap={1}>
                    <CustomTextField

                        error={error.verify('host_name')}
                        helperText={error.msgError('host_name')}
                        label='Nome do Host'
                        value={state?.host_name}
                        name={'host_name'}
                        onChange={handleChange}
                    />
                    <HermesTypeSelect
                        fullWidth
                        handler={(type) => setState(prev => ({ ...prev, type }))}
                        value={state?.type}
                        size="small"
                    />
                    <CustomTextField

                        error={error.verify('ip_address')}
                        helperText={error.msgError('ip_address')}
                        label='IP'
                        value={state?.ip_address}
                        name={'ip_address'}
                        onChange={handleChange}
                    />
                    <CustomTextField

                        error={error.verify('username')}
                        helperText={error.msgError('username')}
                        label='Usuário'
                        value={state?.username}
                        name={'username'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        id="password"
                        error={error.verify('password')}
                        helperText={error.msgError('password')}
                        label='Senha'
                        value={state.password}
                        name={'password'}
                        onChange={handleChange}
                        onBlur={(e) => {
                            if (e.target.value == '' && id) {
                                setState(prev => ({ ...prev, password: '********' }))
                            }
                        }}
                        onFocus={(e) => {
                            if (e.target.value == '********' && id) {
                                setState(prev => ({ ...prev, password: '' }))
                            }
                        }}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                            <IconButton
                                disabled={state.password == '********'}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            )
                          }}
                    />
                    <CustomTextField
                        id="enable_password"
                        error={error.verify('enable_password')}
                        helperText={error.msgError('enable_password')}
                        label='Enable Password'
                        value={state.enable_password}
                        name={'enable_password'}
                        onChange={handleChange}
                        onBlur={(e) => {
                            if (e.target.value == '' && id) {
                                setState(prev => ({ ...prev, enable_password: '********' }))
                            }
                        }}
                        onFocus={(e) => {
                            if (e.target.value == '********' && id) {
                                setState(prev => ({ ...prev, enable_password: '' }))
                            }
                        }}
                        type={showPassword && id ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                            <IconButton
                                disabled={state.enable_password == '********'}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            )
                          }}
                    />
                    <CustomTextField
                        error={error.verify('port')}
                        helperText={error.msgError('port')}
                        label='Porta'
                        value={state?.port}
                        name={'port'}
                        onChange={handleChange}
                    />
                    <TunnelSelect
                        fullWidth
                        admin={false}
                        handler={(tunnel) => setState(prev => ({ ...prev, interface: tunnel }))}
                        value={state?.interface}
                        size="small"
                    />
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                checked={state?.status}
                                onChange={(e) => setState({ ...state, status: e.target.checked ? 1 : 0 })}
                            />
                        }
                            label="Ativo"
                        />
                    </FormGroup>



                </Box>

            </FormDialog>
        </Box>
    )
}
