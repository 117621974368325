import * as React from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ItemMenu, { ListItemMenu } from '../../components/ItemMenu';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import TvIcon from '@mui/icons-material/Tv';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import LockIcon from '@mui/icons-material/Lock';
import SettingsIcon from '@mui/icons-material/Settings';
import DnsIcon from '@mui/icons-material/Dns';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SearchIcon from '@mui/icons-material/Search';
import StorageIcon from '@mui/icons-material/Storage';
import ComputerIcon from '@mui/icons-material/Computer';
// import getClients from '../../pages/prov_service/onusSearch';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ArticleIcon from '@mui/icons-material/Article';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import MapIcon from '@mui/icons-material/Map';
import { useAuth } from '../../components/AuthProvider';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import UserProfile from '../profile';
export const Menu = () => {

  const auth = useAuth();
  const deprovisioning = auth.hasPerm('provisioning_service.deprovision_onu_detail') ? 'provisioning_service.deprovision_onu_detail' : 'provisioning_service.deprovision_onu_simple';
  const provisioning = auth.hasPerm('provisioning_service.provision_onu_detail') ? 'provisioning_service.provision_onu_detail' : 'provisioning_service.provision_onu_simple';
  
  return (
    <List>
      <ItemMenu icon={<DashboardIcon />} name="Dashboard" path="/provisioning/dashboard" perm={"dashboard.get_all"} />
      <ListItemMenu icon={<HomeRepairServiceIcon />} name={"Serv. de OLT"}>
        <ItemMenu icon={<PersonAddAlt1Icon />} name="Adicionar Cliente" path="/provisioning/add_onu" perm={provisioning} />
        <ItemMenu icon={<PersonRemoveIcon />} name="Remover Cliente" path="/provisioning/rem_onu" perm={deprovisioning} />
        <ItemMenu icon={<StorageIcon />} name="OLTs" path="/provisioning/olt" perm="olt.get_all" />
        <ItemMenu icon={<SearchIcon />} name="Onus via OLT" path="/provisioning/client" perm="onu.get_signal" />
        <ItemMenu icon={<InboxIcon />} name="CTOs" path="/provisioning/cto" perm="cto.get_all" />
        <ItemMenu icon={<MapIcon />} name="Mapa" path="/provisioning/map" perm="map.get_all" />
        <ItemMenu icon={<PersonIcon />} name="Clientes" path="/provisioning/onu" perm="onu.get_all" />
        <ItemMenu icon={<ArticleIcon />} name="Templates de Prov." path="/provisioning/profile" perm="profile.get_all" />
        <ItemMenu icon={<ReceiptLongIcon />} name="Log Auditoria" path="/provisioning/audit" perm="log_audit.get_all" />
        <UserProfile/>
      </ListItemMenu>
      {auth.hasPerm("role.get_all") && auth.hasPerm("user.get_all") &&
      <ListItemMenu icon={<SettingsIcon />} name={"Config"}>
        <ItemMenu icon={<GroupIcon />}
          name="Cargos" path="/provisioning/role" perm="role.get_all" />
        <ItemMenu icon={<PersonIcon />}
          name="Usuários" path="/provisioning/user" perm="user.get_all" />
      </ListItemMenu>
        }
    </List>
  )
};

