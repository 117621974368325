import { ThemeProvider } from "@emotion/react";
import { Box, Container, createTheme, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";


export default function NotFound() {
    const theme = createTheme();
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
          <Box sx={{ my: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom sx={{ color: grey[900] }}>
              Error 404
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom sx={{ color: grey[900] }}>
              Página não encontrada.
            </Typography>
          </Box>
  
        </Container>
      </ThemeProvider>
    )
  }