import React, { useCallback, useState } from "react";
import OltSelect from "../../../../components/OltSelect";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Step, StepLabel, Stepper, TextField } from "@mui/material";
import { useFormError } from "../../../../components/Datatable/FormErrorProvider";
import api from "../../../../services/api";
import { useNotify } from "../../../../components/Datatable/NotifyProvider";
import FreeOnuSelect from "../FreeOnuSelectDetail";
import { SearchRounded } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import LineSrvSelect from "../../../../components/LineSrvSelect";
import { useLoading } from "../../../../components/LoadingProvider";
import CustomTextField from "../../../../components/CustomTextField";



export default function RemDetail() {
  const { loading, setLoading } = useLoading()
  const error = useFormError()
  const [searchOnu, setSearchOnu] = useState(false)


  const [waitOlt, setWaitOlt] = useState(true)


  const fetchRem = () => {
    error.setErrors([])
    setLoading(true)
    api.post("provisioning/olt/rem/detail/", { ...state, roteada: state.roteada == 2 })
      .then(res => {
        if (res.data.error) {
          notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
          return
        }
        if (res.data.errors) {
          error.setErrors(res.data.errors)
          return
        }

        notify.setNotify({ open: true, message: "ONU Removida com sucesso", severity: "success" })
        setState({
          sn: "",
          olt: null,
          board: "",
          port: ""
        })
      }

      )
      .finally(() => setLoading(false))
  }
  const [state, setState] = useState({
    sn: "",
    olt: null,
    board: "",
    port: ""
  })
  const notify = useNotify()


  const handleState = useCallback((event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  }, [state])


  return (
    <Box display={"flex"} flexWrap={'wrap'} flexDirection={"column"} justifyContent={"center"}
      sx={{
        width: { xs: "auto", sm: "auto", md: 400 }
      }}
      gap={1}
    >


      <OltSelect

        size="small"

        value={state.olt}
        handler={(e) => { setState({ ...state, olt: e }); setWaitOlt(true) }}
        admin={false}
      />
      <Box display={"flex"} flexDirection={{xs:"column",sm:"row", }} gap={1} >

        <CustomTextField
          size="small" 
          error={error.verify('board')}
          helperText={error.msgError('board')}
          label='Placa'
          value={state.board}
          name={'board'}
          onChange={handleState}
          type="number"
        />
        <CustomTextField
          size="small"
          error={error.verify('port')}
          helperText={error.msgError('port')}
          label='Porta'
          value={state.port}
          name={'port'}
          onChange={handleState}
          type="number"
        />
      </Box>

      <CustomTextField
        size="small"
        error={error.verify('sn')}
        helperText={error.msgError('sn')}
        label='SN'
        value={state.sn}
        name={'sn'}
        onChange={handleState}
        type="text"
      />
      <Box>
      </Box>
      <Button variant="contained"
        onClick={fetchRem}>{"Remover"}</Button>   
    </Box >
  )
}