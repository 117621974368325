import { grey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';



const theme = createTheme({
  typography:{
    fontFamily:[
      'sans-serif',
      'Roboto',
      'Montserrat',
    ]
  },

  MUIDataTableHeadCell: {
    toolButton: {
      justifyContent: 'center'
    },
  },
  
  palette: {
    mode: 'light',
    primary: {
      main: "#e01b29",
    },
  },
  
});

export default theme;
