import { Delete } from "@mui/icons-material"
import { Avatar, Box, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material"
import { green, grey, red } from "@mui/material/colors"
import React  from "react"
import { ONUSearchDetailForm } from "../pages/provisioning/prov_service/OnuSearchDetail"
import { ONUHistorySignalButton } from "./Graphs/SignaHistoryOnuGraph"


export  const OnuItem = ({ id, description, sn, state, handler }) => {
    return (
      <ListItemButton className="item-onu" onClick={() => handler(sn)}
        sx={{
          bgcolor: state == "online" ? green[100] : red[100],
          width: "auto",
          m: 1,
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: state == "online" ? green[500] : red[500] }}>
            {id}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={description} secondary={sn} />
      </ListItemButton>
  
    )
  }

  export default OnuItem

  export  const OnuItemBase = ({ id, description, sn, olt, handler }) => {
    return (
      <ListItem 
        sx={{
          bgcolor:grey[100],
          width: "auto",
          m: 1,
        }}
      >
       

        <ListItemText primary={description} secondary={sn} />
         <ONUSearchDetailForm sn={sn} olt={olt}/>
         <ONUHistorySignalButton sn={sn} title={description + " - " + sn}/>
          <IconButton sx={{mt: 1}} onClick={()=>handler()}>
            <Delete></Delete>
          </IconButton>
      
      </ListItem>
  
    )
  }
