import React, { useState } from 'react'
import { FormControl, TextField, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api from '../services/api'
import { ConnectingAirportsOutlined } from '@mui/icons-material'
import { useFormError } from './Datatable/FormErrorProvider'
import CustomTextField from './CustomTextField'

export default function OltSelect({handler, value, empresa_id,admin = true, size="medium" , ...props }) {
    const [olts, setOLTs] = useState([])
    const [empresa, setOLT] = React.useState(null)
    const error =  useFormError()
    const [loading, setLoading] = React.useState(true)
    React.useLayoutEffect(() => {
        setLoading(true)
        api.get(`${admin ? `admin/provisioning/olt/${empresa_id}/all/`: 'provisioning/olt/prov_serv/olt/all/'}`).then(res => {
          setOLTs(res.data)
        }).finally(() => setLoading(false))
      }, [])
    React.useLayoutEffect(() => {
        setLoading(true)
        if (admin && !empresa_id) {
          setOLTs([])
          return}
        api.get(`${admin ? `admin/provisioning/olt/${empresa_id}/all/`: 'provisioning/olt/prov_serv/olt/all/'}`).then(res => {
          setOLTs(res.data)
        }).finally(() => setLoading(false))
      }, [empresa_id])
  return (

      <FormControl 
      {...props}
      >
      <Autocomplete
       loadingText={"Buscando..."}
       noOptionsText={"Sem opções"}
        loading={loading}
      id="olts-outlined"
      options={olts}
      onChange={(event, newValue) => {
        setOLT(newValue)
        handler(newValue?.id)
      }}
      key={(option) => option.id}
      
      isOptionEqualToValue={(option, value) => value?.id  && option.id == value.id }
      getOptionLabel={(option) => option.name + ' - ' +option.ip   }
      value={value ? olts.find(e => e.id == value) : null}
      filterSelectedOptions
      renderInput={(params) => (
        <CustomTextField
        error={error.verify('olt')}
        helperText={error.msgError('olt')}
        {...params}
        size={size}
        label="OLT"
        />
      )}
      />
      </FormControl>
   
    )
  }