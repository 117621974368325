import React, { useState } from 'react'
import { FormControl, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api from '../services/api'
import { useFormError } from './Datatable/FormErrorProvider'
import CustomTextField from './CustomTextField'


export default function HermesTypeSelect({handler, value, admin = false}) {
    const [types, setTypes] = useState([])
    const error = useFormError()
    const [loading, setLoading] = React.useState(true)
    React.useLayoutEffect(() => {
      setLoading(true)
        api.get(`${admin ? 'admin' : ''}/hermes/host/type/all/`).then(res => {
          setTypes(res.data)
        }).finally(() => setLoading(false))
      }, [])
  return (
    <FormControl 
   
    >
      <Autocomplete
       
        id="types-outlined"
        options={types}
        onChange={(event, newValue) => {
          handler(newValue?.id)
        }}
       key={(option) => option.id}
       loadingText={"Buscando..."}
       noOptionsText={"Sem opções"}
        loading={loading}
        isOptionEqualToValue={(option, value) => value.id  && option.id == value.id }
        getOptionLabel={(option) => option.vendor + ' - ' + option.type + ' - ' + option.protocol}
        value={value? types.find(e => e.id == value): null}
        filterSelectedOptions
        renderInput={(params) => (
          <CustomTextField
          error={error.verify('type')}
          helperText={error.msgError('type')}
            {...params}
            label="Tipo"
          />
        )}
      />
    </FormControl>
  )
}