import React, { useEffect, useState } from 'react';
import { CircularProgress, Paper, Typography } from '@mui/material';
import api from '../services/api';

export default function ItemDashboard({ endpoint, label }) {
    const [state, setState] = useState({
        name: "...",
        value: 0
    })
    const [isRender, setIsRender] = useState(false)
    useEffect(() => {
        api.get(endpoint).then(response => {
            setState({
                name: label,
                value: response.data.value
            })
            setIsRender(true)
        }).catch(error => console.log(error))
    }, [])
    return (
        isRender ? <Item name={state.name} value={state.value} /> :
            <Paper elevation={2}
                

                sx={{
                    flexWrap: 'wrap',
                    maxWidth: 400,
                    minWidth: 200,
                    minHeight: 170,
                    p: 2,
                    flexGrow: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',

                }}
            >
                <CircularProgress
                    disableShrink
                />
            </Paper>
    )
}

const getFontSize = (value) => {
    const length = value.toString().length;
    const baseSize = 4; // Tamanho base da fonte em ems para valores pequenos
    const scaleFactor = 0.8; // Fator de escala para diminuir a fonte à medida que o número cresce

    // Cálculo do tamanho da fonte baseado no comprimento do valor
    // Ajuste o scaleFactor para alterar o quanto a fonte diminui conforme o valor cresce
    const fontSize = baseSize * Math.pow(scaleFactor, length / 4);

    return `${fontSize}em`;
};
function Item({ name, value }) {
    return (
        <Paper elevation={2}

            sx={{
                flexWrap: 'wrap',
                maxWidth: 400,
                minWidth: 200,
                p: 2,
                flexGrow: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',

            }}
        >
            <Typography

                sx={{
                    width: 200,
                    textTransform: 'uppercase',
                    color: 'primary.main',
                    textAlign: 'center',
                    fontFamily: 'Montserrat'
                }}
            >{name}</Typography>
            <Typography
                sx={{
                    width: 'fit-content',
                    textSizeAdjust: 'auto',
                    fontSize: getFontSize(value),
                    fontFamily: 'Montserrat',
                    fontWeight: 400,
                    color: 'primary.main',
                    textAlign: 'center',
                    mb: 3

                }}
            >{value}</Typography>
        </Paper>
    );
}

