import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";


let SearchContext = React.createContext(null);

export function SearchProvider({ children }) {
    let [component, setComponent] = React.useState(null);
    
    return (
        <SearchContext.Provider value={{component, setComponent }}>
        {children}
       
        </SearchContext.Provider>
    );
}

export function useSearch() {
    return React.useContext(SearchContext);
}