import React, { useEffect, useState } from 'react';
import Datatable from '../../components/Datatable';
import FormDialog from '../../components/Datatable/FormDialog';
import { Autocomplete, Checkbox, Box, Button, FormControl, FormGroup, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../components/Datatable/NotifyProvider';
import { useFormError } from '../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../components/AuthAdminProvider';
import EmpresaSelect from '../../components/EmpresaSelect';
import moment from 'moment';
import CustomTextField from '../../components/CustomTextField';
import { FormControlLabel } from '@mui/material';
import ServiceSelect from '../../components/ServiceSelect';
import DateTime from '../../components/DateTime';

export default function License() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [ refresh, setRefresh ] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Olimpo - Licenças"
      }, [])
    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
               handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={()=>setRefresh(!refresh)}
                title='Licenças'
                endpoint='/admin/license'
                options={{
                    customToolbar: () =>
                        <>
                            
                            <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm('service.create')}
                                    onClick={() => setFormState({ open: true, id: null })} 
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: "empresa_name",
                        label: "Empresa",
                    },
                    {
                        name: 'service_description',
                        label: 'Serviço',
                    },
                    {
                        name: 'expire_date',
                        label: 'Data de Expiração',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }

                    },
                    {
                        name: 'status',
                        label: 'Status',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'updated_at',
                        label: 'Atualizado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton disabled={!auth.hasPerm('service.update')}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar'}) {
    const notify = useNotify()
    const error = useFormError()
    const [permissions, setPermissions] = React.useState([])
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {
        empresa: '',
        service: '',
        expire_date: '',
        status: 1, 
        serial: ''
    }
    const [state, setState] = useState(modelState)
    const [serial, setSerial] = useState('')
  
    useEffect(() => {
        setState(modelState)
        setSerial('')
        if (!open) return
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/admin/license', id).then(res => {
                console.log(res.data.data)
                setState(res.data.data)
                setIsLoaded(true)

            }).catch(e => {console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id,open])

 

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {
        if (id) {
            update('/admin/license', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.error){
                    error.setErrors([
                        {field: 'global', msg: res.data.error}
                    ])
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return   
                }
                   if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Licença atualizada com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/admin/license/create', state)
            .then(res => {
                if (res.data.error){
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return
                }
                if (res.data.errors) {
                    error.setErrors(res.data.errors)
                    return
                }
                // handlerSubmit()
                setSerial(res.data)
                notify.setNotify({ open: true, message: 'Licença criada com sucesso.', severity: 'success' })
                error.setErrors([])
            })
            .catch(e => 
                notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >
        <FormDialog
        isLoaded={isLoaded}
        title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{serial ? 'Licença gerada com Sucesso' : id ? 'Editar Licença' : 'Adicionar Licença'} </Typography>
                        <IconButton disabled={!auth.hasPerm("service.delete")} 
                        sx={{display:id? 'block':'none'}}
                        onClick={()=>{
                             if (confirm('Deseja excluir a licença?')){
                                 delete_one('/admin/license', id).then(res => {
                                        notify.setNotify({ open: true, message: 'Licença deletada com sucesso.', severity: 'success' })
                                        handlerClose()
                                        handlerSubmit()
                                    })
                             }
                            }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerSubmit}
                btnSubmit={serial ? false : btnSubmit}
                btnClose={btnClose}
                >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}
                    gap={2}
                    >
                    {serial ? 
                    <>
                    <Typography variant="body" component="div">{serial}</Typography>
                    
                    </>
                    :
                    <>
                    <EmpresaSelect
                    sx={{mt:1}}
                    value={state.empresa}
                    handler={(e) => setState({...state, empresa: e})}
                    disabled={id}
                    />
                    <ServiceSelect
                        value={state?.service}
                        handler={(e) => setState({ ...state, service: e})} />
                    <DateTime
                        sx = {{width:'100%', m:0}}
                        label="Data de Expiração"
                        handler={(e) => setState({ ...state, expire_date: e })}
                        initValue={state.expire_date}
                        />
                    <Typography variant="body" component="div">Serial: {state.serial}</Typography>
                    <FormGroup>
                    <FormControlLabel control={
                        <Checkbox 
                        checked={state?.status}
                        onChange={(e) => setState({...state, status: e.target.checked ? 1 : 0})}
                        />
                    }
                    label="Ativo"
                    />
                    </FormGroup>
                    </>
                    }
                </Box>

            </FormDialog>
        </Box>
    )
}

