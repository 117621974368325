import React from 'react';
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment'; // Importe o moment

export default function({label, handler,initValue,sx}){
  const initialMomentValue = initValue ? moment(initValue) : null;
  const [value, setValue] = React.useState(initialMomentValue);
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>

        <DateTimePicker
        slotProps={{ textField: { size: 'small',
            
        sx:{
            m:1,
            width:200,
            ...sx
        }
     } }}
          label={label}
          renderInput={(params) => <TextField 
         
            {...params}
        
            
            
            />}
          value={ initValue ? moment(initValue) : null}
          onChange={(newValue) => {

            handler(newValue.format("YYYY-MM-DDTHH:mm:ss"))
              setValue(newValue);
            }}
            />
        </LocalizationProvider>
    )
}