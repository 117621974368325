import React, { useState } from 'react'
import { FormControl, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api from '../services/api'
import { ConnectingAirportsOutlined } from '@mui/icons-material'
import { useFormError } from './Datatable/FormErrorProvider'
import CustomTextField from './CustomTextField'

export default function ServiceSelect({handler, value, size="medium" , ...props }) {
    const [ services, setServices] = useState([])
    const [loading, setLoading] = React.useState(true)
    const error =  useFormError()
    React.useLayoutEffect(() => {
        setLoading(true)
        api.get(`/admin/license/service/all`).then(res => {
          setServices(res.data)
        }).finally(() => setLoading(false))
      }, [])
 
  return (
      <FormControl 
      {...props}
      >
      <Autocomplete
      fullWidth
      loadingText={"Buscando..."}
      noOptionsText={"Nenhum serviço cadastrado."}
       loading={loading}
      id=" services-outlined"
      options={ services}
      onChange={(event, newValue) => {
        handler(newValue?.id)
      }}
      key={(option) => option.id}
      
      isOptionEqualToValue={(option, value) => value?.id  && option.id == value.id }
      getOptionLabel={(option) => option.description   }
      value={value ?  services.find(e => e.id == value) : null}
      filterSelectedOptions
      renderInput={(params) => (
        <CustomTextField
        
        error={error.verify('service')}
        helperText={error.msgError('service')}
        {...params}
        size={size}
        label="Service"
        />
      )}
      />
      </FormControl>   
    )
  }