import React, { useEffect, useState } from 'react';
import Datatable from '../../components/Datatable';
import FormDialog from '../../components/Datatable/FormDialog';
import { Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import { useNotify } from '../../components/Datatable/NotifyProvider';
import { useFormError } from '../../components/Datatable/FormErrorProvider';
import { Close, Done, RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../components/AuthProvider';
import moment from 'moment';
import CustomTextField from '../../components/CustomTextField';
import HermesTypeSelect from '../../components/HermesTypeSelect';
import BackupViewButton from '../../components/BackupView';

export default function Backup() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [formAccess, setFormAccess] = useState({
        open: false,
        id: null,
    })
    const error = useFormError()
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Hermes - Backup"

        error.setErrors([])

    }, [])
    return (
        <>
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Backups'
                endpoint='/hermes/backup'
                options={{
                    customToolbar: () =>
                        <>
                            
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'host_name',
                        label: 'Nome',
                    },
                    {
                        name: 'ip',
                        label: 'Ip Address',
                    },
                    {
                        name: "vendor_name",
                        label: "Marca",
                    },
                    {
                        name: 'type_name',
                        label: 'Tipo',
                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <BackupViewButton
                                            id={tableMeta.rowData[0]}
                                        />
                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}


