import React, { useCallback, useState } from "react";
import OltSelect from "../../../../components/OltSelect";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Step, StepLabel, Stepper, TextField } from "@mui/material";
import { useFormError } from "../../../../components/Datatable/FormErrorProvider";
import api from "../../../../services/api";
import { useNotify } from "../../../../components/Datatable/NotifyProvider";
import FreeOnuSelect from "../FreeOnuSelectDetail";
import { SearchRounded } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import LineSrvSelect from "../../../../components/LineSrvSelect";
import { useLoading } from "../../../../components/LoadingProvider";
import CustomTextField from "../../../../components/CustomTextField";



export default function ProvDetail() {
  const { loading, setLoading } = useLoading()
  const error = useFormError()
  const [searchOnu, setSearchOnu] = useState(false)


  const [waitOlt, setWaitOlt] = useState(true)


  const fetchProv = () => {
    error.setErrors([])
    setLoading(true)
    api.post("provisioning/olt/prov/detail/", { ...state, roteada: state.roteada == 2 })
      .then(res => {
        if (res.data.error) {
          notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
          return
        }
        if (res.data.errors) {
          error.setErrors(res.data.errors)
          return
        }

        notify.setNotify({ open: true, message: "ONU Provisionada com sucesso", severity: "success" })
        setState({
          sn: "",
          vlan: "",
          line: null,
          srv: null,
          description: "",
          olt: null,
          board: "",
          port: "",
          gemport: ""
        })
      }

      )
      .finally(() => setLoading(false))
  }
  const [state, setState] = useState({
    sn: "",
    vlan: "",
    line: null,
    srv: null,
    description: "",
    roteada: 1,
    olt: null,
    board: "",
    port: "",
    gemport: ""
  })
  const notify = useNotify()


  const handleState = useCallback((event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  }, [state])


  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}

      sx={{
        width: { xs: "auto", sm: "auto", md: 400 }
      }}
    >
      <OltSelect

        size="small"
        value={state.olt}
        handler={(e) => { setState({ ...state, olt: e }); setWaitOlt(true) }}
        admin={false}
      />
      <Box display={"flex"} gap={1}
      
        sx={{
          flexDirection: {xs: "column", sm: "row"}
        }}
      >

        <CustomTextField
          size="small"
          error={error.verify('board')}
          helperText={error.msgError('board')}
          label='Placa'
          value={state.board}
          name={'board'}
          onChange={handleState}
          sx={{ flexGrow: 1 }}
          type="number"
        />
        <CustomTextField
          size="small"
          error={error.verify('port')}
          helperText={error.msgError('port')}
          label='Porta'
          value={state.port}
          name={'port'}
          onChange={handleState}
          type="number"
          sx={{ flexGrow: 1 }}
        />

        <Button
          size="small"
          sx={{
            height: 40,
            flexGrow: 0
          }}
          variant="contained"
          color="primary"
          onClick={() => { setSearchOnu(true); setWaitOlt(false) }}
          disabled={state.olt === "" || state.board === "" || state.port === ""}
        >
          <SearchRounded />
        </Button>
      </Box>

      <FreeOnuSelect

        disabled={waitOlt}
        olt={state.olt}
        board={state.board}
        port={state.port}
        submit={searchOnu}
        handlerSn={(sn) => setState(prev => ({ ...prev, sn }))}
        handlerLine={(line) => setState(prev => ({ ...prev, line }))}
        handlerSrv={(srv) => setState(prev => ({ ...prev, srv }))}
        handlerSubmit={() => setSearchOnu(false)}

      />
      <CustomTextField
        size="small"

        error={error.verify('vlan')}
        helperText={error.msgError('vlan')}
        label='Vlan'
        value={state.vlan}
        name={'vlan'}
        disabled={waitOlt}
        onChange={handleState}
        type="number"
      />
      <CustomTextField
        disabled={waitOlt}

        size="small"

        error={error.verify('gemport')}
        helperText={error.msgError('gemport')}
        label='Gemport'
        value={state.gemport}
        name={'gemport'}

        onChange={handleState}
        type="number"
      />
      <FormControl size="small">
        <InputLabel id="mode-prov-select-label">Modo</InputLabel>
        <Select
          disabled={waitOlt}
          inputProps={{ size: "small" }}
          labelId="mode-prov-select-label"
          id="mode-prov-select"
          value={state?.roteada}
          label="Modo"
          onChange={e => setState({ ...state, roteada: e.target.value })}
        >
          <MenuItem value={1}>Brigde</MenuItem>
          <MenuItem value={2}>Roteada</MenuItem>

        </Select>
      </FormControl>



      <CustomTextField
        disabled={waitOlt}
        size="small"
        error={error.verify('description')}
        helperText={error.msgError('description')}
        label='Descrição'
        value={state.description}
        name={'description'}
        onChange={handleState}
        type="text"
      />


      <Button variant="contained" disabled={waitOlt}

        onClick={fetchProv}>{"Provisionar"}</Button>

    </Box >
  )
}