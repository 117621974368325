import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, ThemeProvider, Typography, createTheme } from '@mui/material';
import React, { useState } from 'react';
import DateTime from '../DateTime';
import { Search } from '@mui/icons-material';
import DialogProvider, { useDialog } from './DialogProvider';
import theme from '../../themeContent';

function _DialogGraph({ handleClose, open, children, title }) {
  const {query, setQuery, setSearch, search} = useDialog()
  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle id="form-dialog-title">
          <Box display={"flex"} justifyContent={"space-around"} alignContent={"center"}>
            <Typography variant="h6" mt={1} align="center">{title}</Typography>
            <Box>

              <DateTime handler={value => setQuery({ ...query, start: value })}  initValue={query.start} label={"Início"}/>
              <DateTime handler={value => setQuery({ ...query, end: value })} initValue={query.end}  label={"Fim"}/>
              <IconButton disabled={search} sx={{mt:1}} onClick={()=>setSearch(true)}>
                <Search />
              </IconButton>
            </Box>

          </Box>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose} variant="outlined" color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default function (props) {
  return (

    <DialogProvider>
      <_DialogGraph {...props} />
    </DialogProvider>
  )
}