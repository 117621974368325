import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Alert, Box, Checkbox, Container, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Tooltip, Typography } from '@mui/material';
import api from '../services/api';
import moment from 'moment';
import { Delete, Download, Visibility } from '@mui/icons-material';
import fileDownload from 'js-file-download';
import { useAuth } from "../components/AuthProvider"
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
//import "./main.css"
export function BackupDialog(props) {


  const [handlerSnack, setHandlerSnack] = React.useState()


  const item = {
    backup: {
      host_name: '',
      ip: '',
      vendor_name: '',
      type_name: '',
      id: 0
    },
    list: [],



  }
  const [state, setState] = React.useState(item);

  const auth = useAuth()


  React.useEffect(() => {
    if (props.id>0 && props.open) {

      getEditInfo(props.id)
    }
  }, [props.id, props.open])

  const getEditInfo = (id) => {
    
    if (!props.id) return
    api.get(`hermes/backup/${id}`)
      .then(res => {
        //setBackups(res.data.backup)
        setState(res.data.data)

        


      }).catch(err => setState(item))
  }
  const showNotify = (msg, sev = "success") => {
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }

  const [msgSnack, setMsgSnack] = React.useState('')
  const [sevSnack, setSevSnack] = React.useState('')


  return (
    <div>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={() => setHandlerSnack(false)} />
      <Dialog open={props.open} fullWidth={true} scroll={'paper'}
        maxWidth='lg'>

        <DialogTitle>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8} >
              <Typography variant='h6' color='textPrimary' sx={{ mt: 1 }}>
                {state?.backup?.host_name} - {state?.backup?.ip} - {state?.backup?.vendor_name} - {state?.backup?.type_name}
              </Typography>
            </Grid>
            <Grid item xs={12} spacing={1} md={4} sx={{ display: 'flex', justifyContent: "flex-end", alignItems: 'flex-end' }}>
              <Grid item xs={6} md={10}>
                <FormControl fullWidth size='small' >
                  <InputLabel id="timestamp-label">Versão:</InputLabel>
                  <Select
                    labelId="timestamp-label"
                    size="small"
                    id="backup"
                    label="Realizado em:"
                    name='backup'
                    value={state?.backup?.id}
                    onChange={(e) => {
                      getEditInfo(e.target.value)
                     
                    }}
                    >
                    { state.list.map((backup) =>
                      <MenuItem key={backup.id} value={backup.id}>{moment(backup.created_at).format('DD-MM-YYYY HH:mm:ss')} {moment(backup.created_at).locale('pt-br').fromNow()}</MenuItem>
                    ) }
                    </Select>
                  </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                  <Tooltip title="Baixar">
                    <IconButton  disabled={!auth.hasPerm('backup.get_all')} sx={{ ml:2 }} onClick={() => {
                    api.get(`hermes/backup/download/${state?.backup?.id}`).then(res => {
                      //Downlaod txt file
                      fileDownload(res.data, `${state?.backup?.host_name} - ${state?.backup?.ip_address} - ${moment(state?.backup?.created_at).format('DD-MM-YYYY HH:mm:ss')}.txt`);
                    })
                  }}>
                    <Download />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Apagar backup">
                  <IconButton  disabled={!auth.hasPerm('backup.delete')} sx={{ ml:2 }} onClick={() => {
                    const input = window.confirm('Deseja apagar o backup?')
                    if (!input) return
                    api.delete(`hermes/backup/${state.backup.id}`).then(res => {
                      props.handlerClose()
                      console.log(res)
                      showNotify('Backup apagado com sucesso')
                    }).catch(err => showNotify('Erro ao apagar backup', 'error'))
                  }
                  }>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
        
        >
        



         <CodeMirror value={state?.backup?.content} 
         editable={false}
         extensions={[javascript({ jsx: true })]} 
         options={{
           mode: 'javascript',
           theme: 'material',

           lineNumbers: true,
           viewportMargin: 50
          }}
          style={{
            height: '100%',
            width: 'auto',
          }}
          
          />



        </DialogContent>
        <DialogActions>
          <Button sx={{  }} onClick={props.handlerClose}>Fechar</Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

const Snack = (props) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={() => { props.handlerSnack() }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert onClose={() => { props.handlerSnack() }} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}

export default function BackupViewButton({id}) {
  const [open, setOpen] = React.useState(false)
  return(
    <>
    <IconButton
    onClick={()=>setOpen(true)}
    >
      <Visibility />
    </IconButton>
    <BackupDialog open={open} id={id} handlerClose={()=>setOpen(false)} />
    </>
  )

}