import { CircularProgress, Paper, Typography } from "@mui/material";
import React, { PureComponent, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import api from "../../../services/api";
import { green, red } from "@mui/material/colors";


export default function ProvGraph() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        api.get("provisioning/dashboard/count/provision_onu_by_month").then(res => {
            setData(res.data.data)
        }).finally(()=>setLoading(false))
    }, [])
    return (
       
        <Paper elevation={2} >
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center', mb: 2, fontFamily: 'Montserrat', fontWeight: 400 }}>
                Provisionamento
            </Typography>
            <ResponsiveContainer   height={300}  width={"100%"}>
                {loading? <LoadingPage/>
                :
                <LineChart
                
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    bottom: 5,
                }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month_year" />
                    <YAxis  allowDataOverflow />
                    <Tooltip />
                    <Legend />
                    <Line type="linear" dataKey="add_onu" stroke={green[700]} activeDot={{ r: 6 }} isAnimationActive={false} />
                    <Line type="linear" dataKey="rem_onu" stroke={red[700]} activeDot={{ r: 6 }} isAnimationActive={false} />
                </LineChart>
                }
            </ResponsiveContainer>
        </Paper>
    )
}


function LoadingPage(){
    return(
        <Paper elevation={2}
                

        sx={{
           
            height: 300,
            width: "100%",
            p: 2,
          
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

        }}
    >
        <CircularProgress
            disableShrink
        />
    </Paper>
    )
}