import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../../themeContent';
import { Alert, Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Snackbar, ThemeProvider } from '@mui/material';
import api from '../../../services/api';
import { useAuth } from '../../../components/AuthProvider';
import { grey } from '@mui/material/colors';
import { useLoading } from '../../../components/LoadingProvider';
import { useNotify } from '../../../components/Datatable/NotifyProvider';
import { useFormError } from '../../../components/Datatable/FormErrorProvider';
import TunnelSelect from '../../../components/TunnelSelect';

export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()
  const loading = useLoading()
  const item = {
    url_zabbix: '',
    user_zabbix: '',
    password_zabbix: '',
    user_backup: '',
    password_backup: '',
    interface: '',
    port_ssh: '',
    port_telnet: '',
  }

  const [state, setState] = React.useState(item);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
 
  const showNotify = (msg, sev = "success") => {
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }

  const [msgSnack, setMsgSnack] = React.useState('')
  const [sevSnack, setSevSnack] = React.useState('')
  const notify = useNotify()
  const error = useFormError()
  const handlerSubmit = (event) => {
    event.preventDefault()
    const handlerClose = () => {
      props.handlerClose()
      window.location.reload()
    }
      loading.setLoading(true)
      api.post('hermes/host/create/zabbix', state)
      .then(res => {
        if (res.data.error) {
            notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
            return
        }
        if (res.data.errors) {
            console.log(res.data.errors)
            error.setErrors(res.data.errors)
            return
        }
        handlerClose()
        notify.setNotify({ open: true, message: 'Host atualizado com sucesso', severity: 'success' })
        error.setErrors([])
    }).catch((err) => {
          showNotify("Um erro ocorreu...", 'error')
        })
        .finally(() => {
          loading.setLoading(false)
        })
  }
  const auth = useAuth()
  return (
    <ThemeProvider theme={theme}>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={() => setHandlerSnack(false)} />
      <Dialog open={props.open} fullWidth={true} scroll={'paper'}
      
        maxWidth='sm'>

        <Box component="form" onSubmit={handlerSubmit} sx={{backgroundColor: grey[100]}}>
          <DialogTitle>
            Hosts Zabbix
          </DialogTitle>
          <DialogContent>

            <TextField
              required
              disabled={!auth.hasPerm('host.create')}
              size="small"
              margin="dense"
              id="url_zabbix"
              name="url_zabbix"
              label="URL Zabbix"
              type="string"
              fullWidth
              value={state?.url_zabbix}
              onChange={handleChange}
              variant="outlined"
              error={error.verify('url_zabbix')}
              helperText={error.msgError('url_zabbix')}
            />

            <TextField
              disabled={!auth.hasPerm('host.create')}
              size="small"
              required
              margin="dense"
              id="user_zabbix"
              name="user_zabbix"
              label="Usuário Zabbix"
              type="string"
              fullWidth
              step="3600"
              value={state?.user_zabbix}
              onChange={handleChange}
              variant="outlined"
              error={error.verify('user_zabbix')}
              helperText={error.msgError('user_zabbix')}
            />

            <TextField
              disabled={!auth.hasPerm('host.create')}
              size="small"
              required
              margin="dense"
              id="password_zabbix"
              name="password_zabbix"
              label="Senha Zabbix"
              type="string"
              fullWidth
              value={state?.password_zabbix}
              onChange={handleChange}
              variant="outlined"
              error={error.verify('password_zabbix')}
              helperText={error.msgError('password_zabbix')}
              />
            
            <TextField
              disabled={!auth.hasPerm('host.create')}
              size="small"
              required
              margin="dense"
              id="user_backup"
              name="user_backup"
              label="Usuário padrão para acesso ao equipamento"
              type="string"
              fullWidth
              value={state?.user_backup}
              onChange={handleChange}
              variant="outlined"
              error={error.verify('user_backup')}
              helperText={error.msgError('user_backup')}
              />

            <TextField
              disabled={!auth.hasPerm('host.create')}
              size="small"
              required
              margin="dense"
              id="password_backup"
              name="password_backup"
              label="Senha padrão para acesso ao equipamento"
              type="string"
              fullWidth
              value={state?.password_backup}
              onChange={handleChange}
              variant="outlined"
              error={error.verify('password_backup')}
              helperText={error.msgError('password_backup')}
              />

            <TextField
              disabled={!auth.hasPerm('host.create')}
              size="small"
              required
              margin="dense"
              id="port_ssh"
              name="port_ssh"
              label="Porta SSH"
              type="number"
              fullWidth
              value={state?.port_ssh}
              onChange={handleChange}
              variant="outlined"
              error={error.verify('port_ssh')}
              helperText={error.msgError('port_ssh')}
              />

            <TextField
              disabled={!auth.hasPerm('host.create')}
              size="small"
              required
              margin="dense"
              id="port_telnet"
              name="port_telnet"
              label="Porta TELNET"
              type="number"
              fullWidth
              value={state?.port_telnet}
              onChange={handleChange}
              variant="outlined"
              error={error.verify('port_telnet')}
              helperText={error.msgError('port_telnet')}
              />

            <TunnelSelect
              fullWidth
              admin={false}
              handler={(tunnel) => setState(prev => ({ ...prev, interface: tunnel }))}
              value={state?.interface}
              size="small"
            />


          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={props.handlerClose}>Fechar</Button>
            <Button variant='contained' color="primary" disabled={!auth.hasPerm('host.create')} type='submit'> Salvar</Button>
          </DialogActions>
        </Box>
      </Dialog>
    
  </ThemeProvider>
  );
}

const Snack = (props) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={() => { props.handlerSnack() }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert onClose={() => { props.handlerSnack() }} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}