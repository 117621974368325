import React from "react";


export const FormErrorContext = React.createContext();

export default function FormErrorProvider({ children }) {
    const [errors, setErrors] = React.useState([]);
    const verify = (field) => {
        const error =  errors.filter((error) => error.field === field);
        return error.length > 0
    }
    const msgError = (field) => {
        const error =  errors.filter((error) => error.field === field);
        return error.length > 0 ? error[0].msg : '';
    }
    return (
        <FormErrorContext.Provider value={{ errors, setErrors, msgError, verify }}>
            {children}
        </FormErrorContext.Provider>
    );
}

export function useFormError() {
    const context = React.useContext(FormErrorContext);
    if (!context) {
        throw new Error('useFormError must be used within a FormErrorProvider');
    }
    return context;
}
