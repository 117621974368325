import React, { useCallback, useState } from "react";
import CtoSelect from '../../../../components/CtoSelect'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Step, StepLabel, Stepper, TextField } from "@mui/material";
import { useFormError } from "../../../../components/Datatable/FormErrorProvider";
import api from "../../../../services/api";
import { useNotify } from "../../../../components/Datatable/NotifyProvider";
import FreeOnuSelect from "../FreeOnuSelectDetail";
import { SearchRounded } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import ProfileSelect from "../../../../components/ProfileSelect";
import { useLoading } from "../../../../components/LoadingProvider";
import FreeOnuSelectSimple from "../FreeOnuSelectSimple";
import CustomTextField from "../../../../components/CustomTextField";
import { duration } from "moment";



export default function ProvSimple() {
  const { loading, setLoading } = useLoading()
  const error = useFormError()
  const [searchOnu, setSearchOnu] = useState(false)


  const [waitOlt, setWaitOlt] = useState(true)


  const fetchProv = () => {
    error.setErrors([])
    setLoading(true)
    api.post("provisioning/olt/prov/simple/", { ...state, roteada: state.roteada == 2 })
      .then(res => {
        if (res.data.error) {
          notify.setNotify({ open: true, message: res.data.error, severity: 'error', duration: null })
          return
        }
        if (res.data.errors) {
          error.setErrors(res.data.errors)
          return
        }

        notify.setNotify({ open: true, message: "ONU Provisionada com sucesso", severity: "success" })
        setState({
          sn: null,
          profile: null,
          description: "",
          roteada: 1,

        })
      }

      )
      .finally(() => setLoading(false))
  }
  const [state, setState] = useState({
    sn: "",
    vlan: "",
    cto: "",
    line: null,
    srv: null,
    description: "",
    roteada: 1,
    olt: null,
    board: "",
    port: ""
  })
  const notify = useNotify()


  const handleState = useCallback((event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  }, [state])


  return (

  
      <Box flexDirection={"column"} gap={1}  display={"flex"} 
      sx={{
        width: {xs: "auto", sm:"auto", md: 400}
      }}
      >

        <Box display={"flex"} gap={1} flexDirection={"row"}  >

          <CtoSelect
            fullWidth
            size="small"
            value={state.cto}
            handler={(e) => { setState({ ...state, cto: e }); setWaitOlt(true) }}
            admin={false}
          
          />
          
          <Button

            variant="contained"
            color="primary"
            size="small"
            onClick={() => { setSearchOnu(true); setWaitOlt(false) }}
            disabled={!state.cto}
          >
            <SearchRounded />
          </Button>
          
          
        </Box>

        <FreeOnuSelectSimple
          waitOlt={waitOlt}
          cto={state.cto}
          fullWidth
          submit={searchOnu}
          handlerSn={(sn) => setState(prev => ({ ...prev, sn }))}
          handlerSubmit={() => setSearchOnu(false)}

        />
        <ProfileSelect
          fullWidth
          disabled={waitOlt}
          handler={(profile) => setState(prev => ({ ...prev, profile }))}
          size="small"

        />
        <FormControl fullWidth size="small" >
          <InputLabel id="mode-prov-select-label">Modo</InputLabel>
          <Select
            disabled={waitOlt}
            inputProps={{ size: "small" }}
            labelId="mode-prov-select-label"
            id="mode-prov-select-1"
            value={state?.roteada}
            label="Modo"
            onChange={e => setState({ ...state, roteada: e.target.value })}
          >
            <MenuItem value={1}>Brigde</MenuItem>
            <MenuItem value={2}>Roteada</MenuItem>

          </Select>
        </FormControl>


        <CustomTextField
          disabled={waitOlt}
          size="small"
          error={error.verify('description')}
          helperText={error.msgError('description')}
          label='Descrição'
          value={state.description}
          name={'description'}
          onChange={handleState}
          type="text"
          fullWidth
        />




        <Button variant="contained" disabled={waitOlt}
        fullWidth

          onClick={fetchProv}>{"Provisionar"}</Button>

      </Box >
  )
}