import { Box, Paper, ThemeProvider, Typography, createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect } from "react";
import ItemDashboard from "../../../components/LabelDashboard";
import ProvGraph from "./ProvGraph";
import ClientsOlt from "./ClientsOLT";
import theme from "../../../themeContent";
import { useAuth } from "../../../components/AuthProvider";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
    const theme = createTheme({
        palette: {
            primary: {
                main: grey[900],
            },
            secondary: {
                main: grey[900],
            },
        },
    });

    const auth = useAuth()
    const navigate = useNavigate();
    useEffect(() => {
        if (!auth.hasPerm("dashboard.get_all")) {    
            navigate("provisioning/add_onu")
        }
    },[])
    return (
        auth.hasPerm("dashboard.get_all") &&
        <ThemeProvider theme={theme}>
            <Box display="flex" gap={2} marginBottom={2} flexWrap={"wrap"}>
                <ItemDashboard endpoint="/provisioning/dashboard/count/olt" label="OLT" />
                <ItemDashboard endpoint="/provisioning/dashboard/count/cto" label="CTO" />
                <ItemDashboard endpoint="/provisioning/dashboard/count/onu" label="Clientes" />
                <ItemDashboard endpoint="/provisioning/dashboard/count/provisioned_onu" label="Total Clientes Provisionados" />
                {/* <ItemDashboard endpoint="/dashboard/count/deprovisioned_onu" label=" Total Clientes Desprovisionados" />
            <ItemDashboard endpoint="/dashboard/count/diff_provisioned_deprovisioned_onu" label="Total Clientes Saldo" /> */}
            </Box>
            <Box display="flex" gap={2} flexWrap={"wrap"}
                sx={{
                    flexDirection: {xs: "column", sm: "row"}
                }}
            >
                <Box 
                    width={{sm: "100%",md: "100%", lg: "calc( 50% - 8px )"}}
                >
                    <ProvGraph />
                </Box>
                <Box 
                     width={{sm: "100%",md:  "100%",  lg: "calc( 50% - 8px )"}}
                >

                <ClientsOlt />
                </Box>
            </Box>
        </ThemeProvider>
    );
}

function Item({ name, value }) {
    return (
        <Paper elevation={2}

            sx={{
                m: 2,
                p: 2,
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',

            }}
        >
            <Typography
                sx={{
                    textTransform: 'uppercase',
                    color: 'primary.main',
                    textAlign: 'center',
                    fontFamily: 'Montserrat'
                }}
            >{name}</Typography>
            <Typography
                sx={{
                    fontSize: '4em',
                    fontFamily: 'Montserrat',
                    fontWeight: 400,
                    color: 'primary.main',
                    textAlign: 'center',
                    mb: 3

                }}
            >{value}</Typography>
        </Paper>
    );
}