import { blue, red } from '@mui/material/colors';
import React, { PureComponent, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import api from '../../services/api';
import moment from 'moment';
import { useLoading } from '../LoadingProvider';
import { IconButton } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useAuth } from '../AuthProvider'
import DialogGraph from './DialogGraph'
import { useDialog } from './DialogProvider';
const dateFormatter = date => {
  // return moment(date).unix();
  return moment(date).format('DD-MM HH:mm');
};

export default function CTOHistorySignal({ cto }) {
  const [data, setData] = useState([])
  const { setLoading } = useLoading()
  const { query, setQuery, setSearch, search } = useDialog()

  useEffect(() => {
    if (!cto && search) return
    setLoading(true)
    api.get("provisioning/cto/history/signal/" + cto, { params: query }).then(res => {
      
      const averagedData = calculateAverageSignals(res.data);
      setData(averagedData)
    }
    )
      .finally(() => {
        setLoading(false)
        setSearch(false)
      })
  }, [cto, search])


  return (
    <ResponsiveContainer width={1000} height={300} >
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="created_at" direction={"vertical"} />
        <YAxis domain={[-40, 40]} allowDataOverflow />
        <Tooltip />
        <Legend />
        <Line type="linear" dataKey="avgTx" label={"Média TX"} isAnimationActive={false} stroke={blue[600]}  dot={false} strokeWidth={2} />
        <Line type="linear" dataKey="avgRx" label={"Média RX"} isAnimationActive={false} stroke={red[600]} dot={false}  strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  );

}

export const CTOHistorySignalButton = ({ title, cto }) => {
  const auth = useAuth()
  const [open, setOpen] = useState(false)

  return (
    <>
      <IconButton disabled={!auth.hasPerm('cto.get_signal_history')}
        onClick={() => setOpen(true)}
      >
        <TimelineIcon />

      </IconButton>
      <DialogGraph
        title={title}
        open={open}
        handleClose={() => setOpen(false)}
      >
        <CTOHistorySignal cto={cto} />


      </DialogGraph>
    </>
  )
}




function roundToNearestFiveMinutes(date) {
  const newDate = new Date(date);
  const minutes = newDate.getMinutes();
  const roundedMinutes = Math.floor(minutes / 15) * 15;
  newDate.setMinutes(roundedMinutes, 0, 0);
  return newDate.toISOString();
}


function calculateAverageSignals(data) {
  // Filtrar valores com rx ou tx igual a 0
  const filteredData = data.filter(item => item.rx !== 0 && item.tx !== 0);

  // Objeto para armazenar os dados agrupados por intervalos de 5 minutos
  const groupedData = {};

  // Agrupar dados por intervalos de 5 minutos
  filteredData.forEach(item => {
      const roundedDate = roundToNearestFiveMinutes(item.created_at);

      if (!groupedData[roundedDate]) {
          groupedData[roundedDate] = {
              totalRx: 0,
              totalTx: 0,
              count: 0
          };
      }

      groupedData[roundedDate].totalRx += item.rx;
      groupedData[roundedDate].totalTx += item.tx;
      groupedData[roundedDate].count++;
  });

  // Calcular a média de rx e tx para cada intervalo de 5 minutos
  const averagedData = Object.keys(groupedData).map(date => {
      const averageRx = groupedData[date].totalRx / groupedData[date].count;
      const averageTx = groupedData[date].totalTx / groupedData[date].count;
      return {
          date,
          avgRx: averageRx,
          avgTx: averageTx
      };
  });

  return averagedData;
}


