import React, { useState, useEffect} from 'react'
import { CustomTabPanel, a11yProps } from '../../../../components/Tabs';
import { Box, Paper, Tab, Tabs, ThemeProvider, Typography, createTheme } from '@mui/material';
import ProvDetail from './RemDetail';
import ProvSimple from './RemSimple';
import { useFormError } from '../../../../components/Datatable/FormErrorProvider';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../components/AuthProvider';
import theme from '../../../../themeContent';

export default function OnuProv() {
    const [value, setValue] = React.useState(0);
    const error = useFormError()
    const auth = useAuth()
    const navigate = useNavigate()
    useEffect(()=>{
      error.setErrors([])
      
    },[])

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
      <ThemeProvider theme={theme}>
        <Paper elevation={3}>
  
          <Box sx={{ borderBottom: 1, borderColor: 'divider', p:{xs:1, sm:3} }}>
           
            <Typography variant={"h6"}>Remover ONU</Typography>
  
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              {auth.hasPerm("provisioning_service.deprovision_onu_simple") && <Tab label="Simples" {...a11yProps(0)} />}
              {auth.hasPerm("provisioning_service.deprovision_onu_detail") && <Tab label="Detalhado" {...a11yProps(1)} />}
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <ProvSimple/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ProvDetail/>
            </CustomTabPanel>
          </Box>
        </Paper>
      </ThemeProvider>
    )
  }