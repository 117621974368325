import React, { useCallback, useRef, useState } from 'react'
import { CircularProgress, FormControl, TextField, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api, { get_all } from '../services/api'
import { ConnectingAirportsOutlined } from '@mui/icons-material'
import { useFormError } from './Datatable/FormErrorProvider'
import CustomTextField from './CustomTextField'

export default function OnuSelect({handler,  empresa_id, size="medium" , ...props }) {
    const [onus, setOnus] = useState([])
    const [search, setSearch] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [value, setValue] = React.useState(null)
    const error =  useFormError()
    const ref = useRef(null)
    const [keyChange, setkey] = React.useState(false)
   
    React.useLayoutEffect(() => {
      let timeoutId;
      if (search.length>= 3 ){
        timeoutId = setTimeout(()=>fetchData(),500)
      }
      return ()=>clearTimeout(timeoutId)
      
      }, [search])

    const fetchData = ()=>{
      setLoading(true)
      setOnus([])
      get_all("/provisioning/onu",10, 0,"description", 
        "asc",search).then(res=>{
          setOnus(res.data.data)
        }).finally(()=>setLoading(false))
    }
  return (
      <FormControl 
      {...props}
      key={keyChange}
      >
      <Autocomplete
      
      id="onus-outlined"
      options={onus}
      onChange={(event, newValue) => {
        setkey(!keyChange)
        handler(newValue)
        setOnus([])
        setSearch("")
      }}
      key={(option) => option.sn}
      isOptionEqualToValue={(option, value) => value?.port ==value.port  && option.board == value.board   && option.id_from_olt == value.id_from_olt }
      getOptionLabel={(option) => option.description + ' - ' +option.sn   }
      value={value}
      loadingText={"Buscando..."}
      noOptionsText={"Sem opções"}
      filterSelectedOptions
      loading={loading}
      renderInput={(params) => (
        <CustomTextField
      
        onChange={e=> setSearch(e.target.value)}
        {...params}
        size={size}
        label="Adicionar Cliente"
        />
      )}
      />
      </FormControl>
   
    )
  }
  