import React, { useState } from 'react'
import { FormControl, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api from '../services/api'
import { ConnectingAirportsOutlined } from '@mui/icons-material'
import { useFormError } from './Datatable/FormErrorProvider'
import CustomTextField from './CustomTextField'

export default function TunnelSelect({handler, value, admin = true, disabled = false, empresa}) {
    const baseData = {id: null, name: 'Nenhum'}
    const [data, setData] = useState([baseData])
    const error = useFormError()
    const [loading, setLoading] = React.useState(true)
    React.useLayoutEffect(() => {  
      setLoading(true)
      if (!empresa) return
        api.get(`${admin ? `admin/tunnel/all/${empresa}/` : '/hermes/host/tunnel/all'}`,).then(res => {
          setData( res.data)
          
        }).finally(() => setLoading(false))
      }, [empresa])

    React.useLayoutEffect(() => {  
      if (admin) return
      setLoading(true)
        api.get(`/hermes/host/tunnel/all`).then(res => {
          setData( res.data)

        }).finally(() => setLoading(false))
      }, [])
  return (
    <FormControl 
    sx={{ mt: 1, width: '100%' }}
    >
      <Autocomplete
       
       disabled = {disabled  || (!empresa && admin) }
        id="olts-outlined"
        options={data}
        onChange={(event, newValue) => {
          handler(newValue?.name)
        }}
       key={(option) => option.id}

        isOptionEqualToValue={(option, value) => value?.id  && option.id == value.id }
        getOptionLabel={(option) => option.name   }
        value={  value ? data?.find(e => e.name == value) : baseData }
        filterSelectedOptions
        renderInput={(params) => (
          <CustomTextField
          error={error.verify('interface')}
          helperText={error.msgError('interface')}
            {...params}
            label="Interface"
          />
        )}
      />
    </FormControl>
  )
}