import React, { useCallback, useState } from "react";
import CtoSelect from '../../../../components/CtoSelect'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Step, StepLabel, Stepper, TextField } from "@mui/material";
import { useFormError } from "../../../../components/Datatable/FormErrorProvider";
import api from "../../../../services/api";
import { useNotify } from "../../../../components/Datatable/NotifyProvider";
import FreeOnuSelect from "../FreeOnuSelectDetail";
import { SearchRounded } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import ProfileSelect from "../../../../components/ProfileSelect";
import { useLoading } from "../../../../components/LoadingProvider";
import FreeOnuSelectSimple from "../FreeOnuSelectSimple";
import CustomTextField from "../../../../components/CustomTextField";



export default function RemSimple() {
  const { loading, setLoading } = useLoading()
  const error = useFormError()
  const [searchOnu, setSearchOnu] = useState(false)




  const fetchRem = () => {
    error.setErrors([])
    setLoading(true)
    api.post("provisioning/olt/rem/simple/", state)
      .then(res => {
        if (res.data.error) {
          notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
          return
        }
        if (res.data.errors) {
          error.setErrors(res.data.errors)
          return
        }

        notify.setNotify({ open: true, message: "ONU Removida com sucesso", severity: "success" })
        setState({
          sn: null,
          cto: null

        })
      }

      )
      .finally(() => setLoading(false))
  }
  const [state, setState] = useState({
    sn: null,
    cto: null
  })
  const notify = useNotify()

  const handleState = useCallback((event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  }, [state])


  return (
    <Box display={"flex"}
     flexWrap={'wrap'} flexDirection={"column"} justifyContent={"center"}
     gap={1}
      sx={{
        width: { xs: "auto", sm: "auto", md: 400 }
      }}
    >

      <CtoSelect
        fullwidth
        size="small"
        sx={{


        }}
        value={state.cto}
        handler={(e) => { setState({ ...state, cto: e }) }}
        admin={false}
      />


      <CustomTextField
        size="small"
        fullwidth
        error={error.verify('sn')}
        helperText={error.msgError('sn')}
        label='SN'
        value={state.sn}
        name={'sn'}
        onChange={handleState}
        type="text"
      />




      <Button variant="contained" onClick={fetchRem}>{"Remover"}</Button>

    </Box >

  )
}