import { blue, red } from '@mui/material/colors';
import React, { PureComponent, useEffect, useState } from 'react';

import api from '../../services/api';
import moment from 'moment';
import { useLoading } from '../LoadingProvider';
import { IconButton, Table, TableCell, TableContainer, TableRow, TableHead, TableBody } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useAuth } from '../AuthProvider'
import DialogGraph from './DialogGraph'
import { useDialog } from './DialogProvider';

import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const dateFormatter = date => {
  // return moment(date).unix();
  return moment(date).format('DD-MM HH:mm');
};

export default function ONUHistoryDown ({sn}) {
    const [data, setData] = useState([])
    const {setLoading} = useLoading()
    const {query, setQuery, setSearch, search} = useDialog()

    useEffect(()=>{
      if (!sn && search)  return
      setLoading(true)
      api.get("provisioning/onu/history/down/"+sn,{ params: query}).then(res=>{
        setData(res.data)
      })
      .finally(()=>{
        setLoading(false)
        setSearch(false)
    })
    },[sn, search])

 
    return (
      <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
              <TableRow>
                  <TableCell>Causa</TableCell>
                  <TableCell>Data de Criação</TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
              {data?.map((item, index) => (
                  <TableRow key={index}>
                      <TableCell>{item.cause}</TableCell>
                      <TableCell>{item.created_at}</TableCell>
                  </TableRow>
              ))}
          </TableBody>
      </Table>
  </TableContainer>
    );
  
}

export const ONUHistoryDownButton = ({ title, sn }) => {
  const auth = useAuth()
  const [open, setOpen] = useState(false)

  return (
      <>
          <IconButton disabled={!auth.hasPerm('onu.get_history')}
              onClick={() => setOpen(true)}
          >
              <TrendingDownIcon />

          </IconButton>
          <DialogGraph
          title={title}
              open={open}
              handleClose={() => setOpen(false)}
          >
              <ONUHistoryDown sn={sn}/>
              
             
          </DialogGraph>
      </>
  )
}
