import React, { useState } from 'react'
import { FormControl, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api from '../services/api'
import { ConnectingAirportsOutlined } from '@mui/icons-material'
import { useFormError } from './Datatable/FormErrorProvider'
import CustomTextField from './CustomTextField'

export default function EmpresaSelect({handler, value,disabled=false,...props }) {
    const [empresas, setEmpresas] = useState([])
    const [empresa, setEmpresa] = React.useState(null)
    const error = useFormError()
    const [loading, setLoading] = React.useState(true)
    React.useLayoutEffect(() => {
        setLoading(true)
        api.get('admin/empresa/all/').then(res => {
          setEmpresas(res.data)
        }).finally(() => setLoading(false))
      }, [])
  return (
    <FormControl 
    {...props}
    >
      <Autocomplete
        disabled={disabled}
        id="empresas-outlined"
        options={empresas}
        onChange={(event, newValue) => {
          setEmpresa(newValue)
          handler(newValue?.id)
        }}
       key={(option) => option.id}
       loadingText={"Buscando..."}
       noOptionsText={"Sem opções"}
        loading={loading}
        isOptionEqualToValue={(option, value) => value?.id  && option.id == value.id }
        getOptionLabel={(option) => option.cnpj + ' - ' +option.name   }
        value={value ? empresas.find(e => e.id == value) : null}
        filterSelectedOptions
        renderInput={(params) => (
          <CustomTextField
          error={error.verify('empresa')}
            helperText={error.msgError('empresa')}
            {...params}
            label="Empresa"
          />
        )}
      />
    </FormControl>
  )
}