import { Box } from "@mui/material";

export function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Box  display={"flex"} justifyContent={"center"}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box  sx={{ p: 3 }}>{children}</Box>}
      </Box>
    );
  }
  

  export function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }