import { useEffect, useState } from "react"
import { useNotify } from "../../../components/Datatable/NotifyProvider"
import api from "../../../services/api"
import { useFormError } from "../../../components/Datatable/FormErrorProvider"
import { Autocomplete, Box, FormControl, TextField } from "@mui/material"
import { useLoading } from '../../../components/LoadingProvider';
import CustomTextField from "../../../components/CustomTextField"
export default function FreeOnuSelect({ olt, board, port, handlerSn, handlerSrv, handlerLine, waitOlt, disabled, handlerSubmit, submit = false, size = "small", ...props }) {
  const notify = useNotify()
  const [data, setData] = useState({

    sns: [],
    lines: [],
    srvs: []
  })
  const error = useFormError()
  const loading = useLoading()
  const [dataForm, setDataForm] = useState({
    sn: null,
    line: null,
    srv: null
  })
  useEffect(() => {
    if (olt === "" || board === "" || port == "") return
    if (!submit) return
    fetchData()
    handlerSubmit()
  }, [submit])

  const fetchData = () => {
    setDataForm({
      sns: [],
      lines: [],
      srvs: []
    })
    loading.setLoading(true)

    api.post("provisioning/olt/onu/free_detail", { olt, board, port }).then(res => {
      if (res.data.error) {
        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
        return
      }
      if (res.data.errors) {
        error.setErrors(res.data.errors)
        return
      }
      error.setErrors([])
      setData(res.data)
    }).finally(() => loading.setLoading(false))

  }

  return (
    <Box display={waitOlt ? "none" : "flex"} flexDirection={"column"} >
      <FormControl
        {...props}
      >
        <Autocomplete
        
          disabled={disabled}
          id="olts-outlined"
          options={data.sns}
          value={dataForm.sn}
          onChange={(event, newValue) => {
            setDataForm({ ...dataForm, sn: newValue })
            handlerSn(newValue)
          }}
          key={(option) => option.id + "sn"}

          isOptionEqualToValue={(option, value) => value && option == value}
          getOptionLabel={(option) => option}
          filterSelectedOptions
          renderInput={(params) => (
            <CustomTextField
              error={error.verify('sn')}
              helperText={error.msgError('sn')}
              {...params}
              size={size}
              label="SNs"
            />

          )}
        />
      </FormControl>
      <FormControl disabled={true}>
        <Autocomplete
          disabled={disabled}
          {...props}
          sx={{ mt: 1 }}
          id="srv-outlined"
          options={data.srvs}
          onChange={(event, newValue) => {
            setDataForm({ ...dataForm, srv: newValue })
            handlerSrv(newValue?.id)
          }}
          key={(option) => option.id + "srv"}

          isOptionEqualToValue={(option, value) => value && option?.id == value.id}
          getOptionLabel={(option) => option && option?.id + ' - ' + option?.name}
          value={dataForm.srv}
          filterSelectedOptions
          renderInput={(params) => (
            <CustomTextField
              error={error.verify('srv')}
              helperText={error.msgError('srv')}
              {...params}
              size={size}
              label="Service Profile"
            />
          )}
        />
        <Autocomplete
          sx={{ mt: 1 }}
          disabled={disabled}
          id="line-outlined"
          options={data.lines}
          onChange={(event, newValue) => {
            setDataForm({ ...dataForm, line: newValue })
            handlerLine(newValue?.id)
          }}
          key={(option) => option.id + "line"}

          isOptionEqualToValue={(option, value) => value && option?.id == value.id}
          getOptionLabel={(option) => option && option?.id + ' - ' + option?.name}
          value={dataForm.line}
          filterSelectedOptions
          renderInput={(params) => (
            <CustomTextField
              error={error.verify('line')}
              helperText={error.msgError('line')}
              {...params}
              size={size}
              label="Line Profile"
            />
          )}
        />
      </FormControl>
    </Box>

  )
}