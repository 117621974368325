import React, { useState } from 'react'
import { FormControl, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api from '../services/api'
import { ConnectingAirportsOutlined } from '@mui/icons-material'
import { useFormError } from './Datatable/FormErrorProvider'
import CustomTextField from './CustomTextField'

export default function CtoSelect({handler, value, empresa_id,admin = true, size="medium" , ...props }) {
    const [ ctos, setCtos] = useState([])
    const [empresa, setOLT] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const error =  useFormError()
    React.useLayoutEffect(() => {
        setLoading(true)
        api.get(`provisioning/cto/select/all`).then(res => {
          setCtos(res.data)
        }).finally(() => setLoading(false))
      }, [])
 
  return (
     ctos.length > 0? 
      <FormControl 
      {...props}
      >
      <Autocomplete
      fullWidth
      loadingText={"Buscando..."}
      noOptionsText={"Sem opções"}
       loading={loading}
      id=" ctos-outlined"
      options={ ctos}
      onChange={(event, newValue) => {
        setOLT(newValue)
        handler(newValue?.id)
      }}
      key={(option) => option.id}
      
      isOptionEqualToValue={(option, value) => value?.id  && option.id == value.id }
      getOptionLabel={(option) => option.description   }
      value={value ?  ctos.find(e => e.id == value) : null}
      filterSelectedOptions
      renderInput={(params) => (
        <CustomTextField
        
        error={error.verify('cto')}
        helperText={error.msgError('cto')}
        {...params}
        size={size}
        label="CTO"
        />
      )}
      />
      </FormControl>: 
      <CustomTextField
      {...props}
      fullWidth
      size={size}
      error={error.verify('cto')}
      helperText={error.msgError('cto')}
      label={admin && !empresa_id ? "Selecione uma empresa" : "Nenhuma CTO foi cadastrada"}
      disabled={true}
      />
   
    )
  }