import React from "react";

import { Route, Routes } from "react-router-dom";
import { AuthProvider, RequireAuth } from "../components/AuthProvider";


import Role from '../pages/user/role';

import User from '../pages/user/user';

import MainFrame from '../components/MainFrame';
import NotAuthorized from "../pages/NotAuthorized";
import NotFound from "../pages/NotFound";
import { Menu} from '../pages/hermes/Menu';
import Host from "../pages/hermes/Host";
import Backup from "../pages/hermes/Backup";
import Dashboard from "../pages/hermes/Dashboard";
import Queue from "../pages/hermes/Queue";
import ErrorBackup from "../pages/hermes/ErroBackup";
import LogAudit from "../pages/hermes/LogAudit";
import HermesLogo from '../components/imgs/hermesLogo.png';
import Profile from "../pages/profile";
export default function HermesRoutes() {
    return (
        <>
            <Route path="/hermes" element={
                <RequireAuth service={"hermes"}>
                  <MainFrame
                    logo={HermesLogo}
                  menuComponent={<Menu/>}
                  />
                </RequireAuth>
            }> 
            <Route path="" element={<Dashboard />} />
            
            <Route path="host" element={<Host />} />
            <Route path="backup" element={<Backup />} />
            <Route path="queue" element={<Queue />} />
            <Route path="audit" element={<LogAudit />} />
            <Route path="error-backup" element={<ErrorBackup />} />
                {/* 
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="client" element={<OnusProv />} />
                <Route path="olt" element={<ProvOltUser />} />
                <Route path="cto" element={<CTO />} />
                <Route path="profile" element={<Profile />} />
                <Route path="add_onu" element={<ProvAdd />} />
                <Route path="rem_onu" element={<ProvRem />} />
                <Route path="onu" element={<ONU />} />
                <Route path="map" element={<Map />} />
                <Route path="audit" element={<LogAuditClient />} /> */}
                <Route path="profile" element={<Profile />} />
                <Route path="role" element={<Role />} />
                <Route path="user" element={<User />} />
                <Route path="403" element={<NotAuthorized />} />
                <Route path="*" element={<NotFound />} />
            </Route>
           
        </>

    )
}