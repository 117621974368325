import React, { useState } from 'react'
import { FormControl, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api from '../services/api'
import { useFormError } from './Datatable/FormErrorProvider'
import CustomTextField from './CustomTextField'


export default function OltTypeSelect({handler, value, admin = true}) {
    const [types, setTypes] = useState([])
    const error = useFormError()
    const [loading, setLoading] = React.useState(true)
    React.useLayoutEffect(() => {
        api.get(`${admin ? 'admin' : ''}/provisioning/olt/type/all/`).then(res => {
          setTypes(res.data)
        }).finally(() => setLoading(false))
      }, [])
  return (
    <FormControl 
    sx={{ mt: 1 }}
    >
      <Autocomplete
        loadingText={"Buscando..."}
        noOptionsText={"Sem opções"}
         loading={loading}
        id="types-outlined"
        options={types}
        onChange={(event, newValue) => {
          handler(newValue?.id)
        }}
       key={(option) => option.id}

        isOptionEqualToValue={(option, value) => value.id  && option.id == value.id }
        getOptionLabel={(option) => option.description  }
        value={value? types.find(e => e.id == value): null}
        filterSelectedOptions
        renderInput={(params) => (
          <CustomTextField
          error={error.verify('type')}
          helperText={error.msgError('type')}
            {...params}
            label="OLT"
          />
        )}
      />
    </FormControl>
  )
}