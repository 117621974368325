import axios from "axios";

import globalRouter from "../../src/components/globalRouter";

const api = axios.create({
  
    baseURL:process.env.REACT_APP_API_URL+"/api/v1",
});



export default api


api.interceptors.response.use((response) => {
    return response
},function (error) {
    switch (error.response.status) {
        //case 401:
        //    localStorage.removeItem("token")
        //    globalRouter.navigate('/login')
        //    break;
        case 403:
            globalRouter.navigate('/403')
            break;
        default:
            console.log(error.response)
            return Promise.reject(error)
            
    }

})


export const  get_all = async (endpoint, limit=10, offset=0, order_by=undefined, order=undefined, query="") => {
    const response = await api.post(endpoint, {limit, offset, order_by, order, query})
    //await new Promise(r => setTimeout(r, 2000));
    return response
}

export const  get_one = async (endpoint, id) => {
    const response = await api.get(endpoint+"/"+id)
    return response
}

export const  create = async (endpoint, data) => {
    const response = await api.post(endpoint, data)
    return response
}

export const  update = async (endpoint, id, data) => {
    const response = await api.put(endpoint+"/"+id, data)
    return response
}

export const delete_one = async (endpoint, id) => {
    const response = await api.delete(endpoint+"/"+id)
    return response
}