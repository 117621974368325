import React, { useState } from 'react'
import { FormControl, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api from '../services/api'
import { ConnectingAirportsOutlined } from '@mui/icons-material'
import { useFormError } from './Datatable/FormErrorProvider'
import CustomTextField from './CustomTextField'

export default function OltProxySelect({ handler, value, empresa_id, admin = true, disabled = false, ...props }) {
  const baseData = { id: null, name: 'Nenhum', ip: '' }
  const [data, setData] = useState([baseData])
  const error = useFormError()
  const [loading, setLoading] = React.useState(true)
  React.useLayoutEffect(() => {
    setLoading(true)
    if (!empresa_id) return
    api.get(`${admin ? 'admin/' : ''}provisioning/olt/proxy/${empresa_id}/all`,).then(res => {
      setData(res.data)
    }).finally(() => setLoading(false))
  }, [empresa_id])

  React.useLayoutEffect(() => {
    if (admin) return
    setLoading(true)
    api.get(`${admin ? 'admin/' : ''}provisioning/olt/proxy/all`).then(res => {
      setData(res.data)
    }).finally(() => setLoading(false))
  }, [])
  return (
    <FormControl
      {...props}
    >
      <Autocomplete
        loadingText={"Buscando..."}
        noOptionsText={"Sem opções"}
        loading={loading}
        disabled={disabled}
        id="olts-outlined"
        options={data}
        onChange={(event, newValue) => {
          handler(newValue?.id)
        }}
        key={(option) => option.id}

        isOptionEqualToValue={(option, value) => value?.id && option.id == value.id}
        getOptionLabel={(option) => option.name + ' - ' + option.ip}
        value={value ? data?.find(e => e.id == value) : baseData}
        filterSelectedOptions
        renderInput={(params) => (
          <CustomTextField
            error={error.verify('proxy')}
            helperText={error.msgError('proxy')}
            {...params}
            label="Proxy"
          />
        )}
      />
    </FormControl>
  )
}