import React, { useState } from 'react';

import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import moment from 'moment';
export const DialogContext = React.createContext();

export default function DialogProvider({ children }) {
    const [query, setQuery] = React.useState({
        start: moment().subtract(15, 'days').toISOString(),
        end: moment().toISOString(),
    });

    const [search, setSearch] = useState(false)

    return (
        <DialogContext.Provider value={{ query, setQuery,search, setSearch }}>
           
            
            {children}
        </DialogContext.Provider>
    );
}

export function useDialog() {
    const context = React.useContext(DialogContext);
    if (!context) {
        throw new Error('useNotify must be used within a DialogProvider');
    }
    return context;
}


