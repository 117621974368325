import {  Box, Container, Grid, Paper, ThemeProvider, Typography } from "@mui/material";
import HostNoBackup from "./hosts-no-backup"


import TotalHosts from "./TotalHosts";
import TotalBackup from "./TotalBackups";
import TimeBackup from "./TimeBackup";
import ActiveHosts from "./ActiveHosts";
import TotalActiveHostBackups from "./TotalActiveHostBackups";
import TotalActiveHostsWithoutBackup from "./TotalActiveHostsWithoutBackup";
import ErrorBackup from "../ErroBackup";

import theme from "../../../themeContent";
import QueueBackup from "../Queue"
export default function(){
    return(
      <ThemeProvider theme={theme}>
        <Container>
      <Box sx={{
       
      }}>
      <Grid container spacing={3} maxWidth={"lg"}>
      <Grid item xs={12} md={6} lg={4}>
        <TotalHosts/>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TotalBackup/>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TimeBackup/>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <ActiveHosts/>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TotalActiveHostBackups/>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TotalActiveHostsWithoutBackup/>
      </Grid>
        </Grid>
      </Box>
     
      <Grid item  xs={12} md={12} mb={3} >
        <ErrorBackup />
      </Grid>
      <Grid item xs={12} md={12} >
        <QueueBackup />
      </Grid>
        </Container>

    </ThemeProvider>
      
    )
}