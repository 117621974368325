import * as React from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ItemMenu, { ListItemMenu } from '../../components/ItemMenu';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import InboxIcon from '@mui/icons-material/Inbox';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ComputerIcon from '@mui/icons-material/Computer';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import { List } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DescriptionIcon from '@mui/icons-material/Description';
import LockIcon from '@mui/icons-material/Lock';
export const MenuAdmin = ({ open }) => {

  return (
    <List>

      {/* <ItemMenu icon={<DashboardIcon />} admin={true}
          name="Dashboard" path="/admin" perm="user.get_all"/> */}
      <ItemMenu icon={<BusinessIcon />} name="Empresa" path="/admin/empresa" perm="empresa.get_all" admin />
      <ItemMenu icon={<SettingsInputComponentIcon />} name="Tunneis GRE" path="/admin/provisioning/gre" perm="service.get_all" admin />
      <ItemMenu icon={<ReceiptLongIcon />} name="Log Auditoria" path="/admin/audit" perm={"false"} admin />
      <ListItemMenu icon={<HomeRepairServiceIcon />} name={"Licenças"}>
        <ItemMenu icon={<RequestQuoteIcon />} name="Serviços" path="/admin/service" perm="service.get_all" admin />
        <ItemMenu icon={<LockIcon />} name="Licensas" path="/admin/license" perm="service.get_all" admin />
      </ListItemMenu>
      <ListItemMenu icon={<HomeRepairServiceIcon />} name={"Serv. de Backup"}>
        <ItemMenu icon={<RequestQuoteIcon />} name="Serv. de Backup" path="/admin/hermes/service" perm="service.get_all" admin />
      </ListItemMenu>
      <ListItemMenu icon={<HomeRepairServiceIcon />} name={"Serv. de OLT"}>
        <ItemMenu icon={<RequestQuoteIcon />} name="Serv. de OLT" path="/admin/provisioning/service" perm="service.get_all" admin />
        <ItemMenu icon={<StorageIcon />} name="OLTs" path="/admin/provisioning/olt" perm="olt.get_all" admin />
        <ItemMenu icon={<ComputerIcon />} name="Proxies" path="/admin/provisioning/proxy" perm="proxies.get_all" admin />
        <ItemMenu icon={<DescriptionIcon />} name="Log" path="/admin/provisioning/log" perm="olt.get_all" admin />
        {/* <ItemMenu icon={<InboxIcon />} name="CTOs" path="/admin/provisioning/cto" perm="cto.get_all" admin /> */}
      </ListItemMenu>
      <ListItemMenu icon={<PersonIcon />} name={"Acessos"}>
        <ItemMenu icon={<GroupIcon />} name="Cargos" path="/admin/access/role" perm="role_client.get_all" admin />
        <ItemMenu icon={<PersonIcon />} name="Usuários" path="/admin/access/user" perm="user_client.get_all" admin />
      </ListItemMenu>
      <ListItemMenu icon={<SettingsIcon />} name={"Config"}
      >
        <ItemMenu icon={<GroupIcon />}
          name="Cargos" path="/admin/user/role" perm="role.get_all" admin={true} />
        <ItemMenu icon={<PersonIcon />}
          name="Usuários" path="/admin/user" perm="user.get_all" admin={true} />
      </ListItemMenu>

    </List>
  )
};

export const secondaryListItems = (
  <React.Fragment>

  </React.Fragment>
);
