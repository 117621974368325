import { useEffect, useRef, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material"
import { LayerGroup, LayersControl, MapContainer, Marker, Polyline, Popup, TileLayer, Tooltip, useMap } from 'react-leaflet'
import L, { Canvas, Layer } from 'leaflet'
import 'leaflet/dist/leaflet.css';
import './style.css'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';

import api from "../../../../services/api";
import { Person, Search } from "@mui/icons-material";
import iconRed from "./iconRed.svg"
import iconGreen from "./iconGreen.svg"
import iconOrange from "./iconOrange.svg"
import personGreen from "./personGreen.svg"
import personRed from "./personRed.svg"
import FormClient from "../Cto/FormClient";
import { useSearch } from "../../../../components/SearchProvider";
import OnuSelect from "../../../../components/OnuSelect";
import SearchMap from "../../../../components/SearchMap";
import { grey } from "@mui/material/colors";



function MapContent() {

    const [ctos, setCtos] = useState([])
    const [onus, setOnus] = useState([])
    const [onusOff, setOnusOff] = useState([])
    const [view, setView] = useState([-19.841644, -43.986511])
    const { setComponent } = useSearch()
    const map = useMap()


    useEffect(() => {
        if (view) {
            map.setView(view, 5)
        }
    }, [view])

    const fetchCtos = () => api.get("provisioning/cto/map/all").then(res => {

        setCtos(res.data)
    })

    const fetchOnus = () => api.get("provisioning/cto/map/onu/all").then(res => {

        setOnus(res.data)
    })
    const fetchOnusOff = () => api.get("provisioning/onu/map/offline/").then(res => {

        setOnusOff(res.data)
    })
    useEffect(() => {
        fetchCtos()
        fetchOnus()
        fetchOnusOff()
        setComponent(

            <Box
                bgcolor={grey[100]}
            >

                <SearchMap
                    handler={coords => setView(coords)}
                    sx={{
                        width: 300,
                        bgColor: grey[100]
                    }}

                />
            </Box>

        )

        return () => {
            setComponent(null)
        }
    }, [])

    useEffect(() => {
        const id = setInterval(fetchOnusOff, 1000 * 60)
        return () => {
            clearInterval(id)
        }
    }, [])


    return (

        <>

            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LayersControl position="topright" >
                <LayersControl.Overlay checked name="CTOS">
                    <LayerGroup>
                        <MarkerClusterGroup
                        //iconCreateFunction={createClusterCustomIcon}
                        // spiderLegPolylineOptions={{
                        //     weight: 0,
                        //     opacity: 0,
                        // }}
                        >

                            {ctos?.map((cto, i) => {

                                return (


                                    <Marker key={i} position={[Number(cto.lat), Number(cto.long)]} icon={new L.Icon({
                                        iconUrl: cto.ports_used / cto.capacity > 0.8 ? iconRed : cto.ports_used / cto.capacity >= 0.6 ? iconOrange : iconGreen,
                                        iconRetinaUrl: iconGreen,
                                        popupAnchor: [-0, -0],
                                        iconSize: [32, 45],
                                    })} >
                                        <Tooltip >

                                            {cto.description}
                                            <Box>

                                            </Box>

                                        </Tooltip>
                                        <Popup>
                                            <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignContent={"center"}>

                                                <Typography>
                                                    CTO: {cto.description}
                                                </Typography>
                                                <Box display={"flex"} m={2}>

                                                    <FormClient id={cto.id} />
                                                </Box>
                                            </Box>
                                        </Popup>

                                    </Marker>
                                )
                            }
                            )}
                        </MarkerClusterGroup>

                    </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay checked name="Clientes">
                    <LayerGroup>
                        <MarkerClusterGroup
                            spiderLegPolylineOptions={{
                                weight: 0,
                                opacity: 0,
                            }}
                        >


                            {onus?.map((onu, i) => {

                                return (
                                    <Marker key={i + "onu"} position={[Number(onu.lat), Number(onu.long)]} icon={new L.Icon({
                                        iconUrl: personGreen,
                                        iconRetinaUrl: personGreen,
                                        popupAnchor: [-0, -0],
                                        iconSize: [32, 45],
                                    })} >
                                        <Tooltip >

                                            ONU: {onu.description} - {onu.sn}
                                            <Box>

                                            </Box>

                                        </Tooltip>
                                    </Marker>
                                )
                            }

                            )}


                        </MarkerClusterGroup>
                    </LayerGroup>


                </LayersControl.Overlay>
                <LayersControl.Overlay checked name="Clientes OFFLINE">
                    <LayerGroup>
                        <MarkerClusterGroup
                            iconCreateFunction={createClusterCustomIcon}
                            spiderLegPolylineOptions={{
                                weight: 0,
                                opacity: 0,
                            }}
                        >


                            {onusOff?.map((onu, i) => {

                                return (
                                    <Marker key={i + "onuoff"} position={[Number(onu.lat), Number(onu.long)]} icon={new L.Icon({
                                        iconUrl: personRed,
                                        iconRetinaUrl: personRed,
                                        popupAnchor: [-0, -0],
                                        iconSize: [32, 45],
                                    })} >
                                        <Tooltip >

                                            Cliente: {onu.description} - {onu.sn}
                                            <Box>

                                            </Box>

                                        </Tooltip>
                                    </Marker>
                                )
                            }

                            )}


                        </MarkerClusterGroup>
                    </LayerGroup>


                </LayersControl.Overlay>
            </LayersControl>
        </>


    )
}

export default function () {

    return (

        <Box component={"map"} >
            <MapContainer zoom={4} id="map"
                style={{ height: "calc( 100vh - 64px)", width: "100wh" }}
            >
                <MapContent />
            </MapContainer>
        </Box>
    )

}

const createClusterCustomIcon = (cluster) => {
    const count = cluster.getChildCount();
    let size = 'LargeXL';

    if (count < 10) {
        size = 'Small';
    }
    else if (count >= 10 && count < 100) {
        size = 'Medium';
    }
    else if (count >= 100 && count < 500) {
        size = 'Large';
    }
    const options = {
        cluster: `markerCluster${size}`,
    };

    return L.divIcon({
        html:
            `<div style="
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
        ">
            <span class="markerClusterLabel" style="
                color: white;
                font-weight: bold;
                position: relative;
                z-index: 1;
            ">${count}</span>
            <div style="
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 50%;
                background: radial-gradient(circle, rgba(255, 0, 0, 1) 40%, rgba(255, 0, 0, 0) 70%);
                z-index: 0;
            "></div>
        </div>`,
        className: `${options.cluster}`,
    });
};