import { blue, red } from '@mui/material/colors';
import React, { PureComponent, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import api from '../../services/api';
import moment from 'moment';
import { useLoading } from '../LoadingProvider';
import { IconButton } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useAuth } from '../AuthProvider'
import DialogGraph from './DialogGraph'
import { useDialog } from './DialogProvider';
const dateFormatter = date => {
  // return moment(date).unix();
  return moment(date).format('DD-MM HH:mm');
};

export default function ONUHistorySignal ({sn}) {
    const [data, setData] = useState([])
    const {setLoading} = useLoading()
    const {query, setQuery, setSearch, search} = useDialog()

    useEffect(()=>{
      if (!sn && search)  return
      setLoading(true)
      api.get("provisioning/onu/history/signal/"+sn,{ params: query}).then(res=>setData(res.data?.map(item=> ({RX: item.rx, TX: item.tx, created_at: dateFormatter(item.created_at)}))))
      .finally(()=>{
        setLoading(false)
        setSearch(false)
    })
    },[sn, search])

 
    return (
      <ResponsiveContainer width={1000} height={300} >
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="created_at"  direction={"vertical"} />
          <YAxis domain={[-40, 40]} allowDataOverflow />
          <Tooltip />
          <Legend />
          <Line type="linear" dataKey="TX"  isAnimationActive={false} stroke={blue[600]} strokeWidth={2} dot={false}  activeDot={{ r: 5 }}/>
          <Line type="linear" dataKey="RX"  isAnimationActive={false} stroke={red[600]} strokeWidth={2} dot={false} activeDot={{ r: 5 }} />
        </LineChart>
      </ResponsiveContainer>
    );
  
}

export const ONUHistorySignalButton = ({ title, sn }) => {
  const auth = useAuth()
  const [open, setOpen] = useState(false)

  return (
      <>
          <IconButton disabled={!auth.hasPerm('onu.get_history')}
              onClick={() => setOpen(true)}
          >
              <TimelineIcon />

          </IconButton>
          <DialogGraph
          title={title}
              open={open}
              handleClose={() => setOpen(false)}
          >
              <ONUHistorySignal sn={sn}/>
              
             
          </DialogGraph>
      </>
  )
}
